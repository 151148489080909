import { CosmosTableRow } from "./cosmos-table-row";

export class CosmosTableData {
    id: string;
    partitionKey: string;
    itemType: string;
    schemaId: string;
    name: string;
    lastUpdate: string;
    hasChanges: boolean;
    rows: CosmosTableRow[];
    order: number;
}
