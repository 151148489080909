<div class="container">
    <div [id]="tableName" class="tabulator-container"></div>
    <div class="exports">
        <div>{{strings["csv"]}}</div>
        <img class="csv-download-icon" src="../../assets/images/icons/FileDownload.svg" (click)="downloadCsv()"
            [alt]="strings['downloadAsCSV']">

        <div>{{strings["json"]}}</div>
        <img class="json-download-icon"  src="../../assets/images/icons/FileDownload.svg" (click)="downloadJson()"
        [alt]="strings['downloadAsJSON']">
    </div>
</div>