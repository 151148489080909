import { RunDataContent } from '../../run-data-content';

export class DataLakeRunDataListing {
    public name: string;
    public runDataContent: RunDataContent;

    constructor(name: string) {
        this.name = name;
    }
}
