import { Component, Input, OnChanges } from '@angular/core';
import * as moment from 'moment'
import Tabulator from 'tabulator-tables';
import { TableSchema } from '../../models/table-schema';
import { TableService } from '../../services/table.service';
import { ModalService } from '../../modal'
import { TenantService } from '../../services/tenant.service';
import { ToastService } from 'src/app/services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { DisplayService } from 'src/app/services/display.service';
import { MyAccess } from 'src/app/models/my-access';
import { AdminService } from 'src/app/services/admin.service';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';

@Component({
  selector: 'app-tabulator-user-table',
  templateUrl: './tabulator-user-table.component.html',
  styleUrls: ['./tabulator-user-table.component.css'],
})
export class TabulatorUserTableComponent implements OnChanges {
  @Input() tableInfo: any;
  files: any[] = [];
  schema: TableSchema;
  tab: HTMLDivElement = null;
  tabHeaders: any[] = [];
  tabRows: any[] = [];
  table: Tabulator;
  addRowShow: boolean = true;
  duplicateSelectedRowsShow: boolean = true;
  deleteSelectedRowsShow: boolean = true;
  undeleteSelectedRowsShow: boolean = true;
  editSelectedRowsShow: boolean = true;
  saveShow: boolean = true;
  public tableIdX = new BehaviorSubject<string>("");
  public $tableIdX = this.tableIdX.asObservable();
  public drawn: boolean = false;
  public tableId: boolean = false;

  public tableGrayout: boolean = false;
  public showOriginal: boolean = true;
  public showChanged: boolean = true;
  public showNew: boolean = true;
  public showDeleted: boolean = false;
  public showNewDeleted: boolean = false;
  public showOriginalToolTipKey: string = "hideOriginalRecords";
  public showChangedToolTipKey: string = "hideEditedOriginalRecords";
  public showNewToolTipKey: string = "hideNewRecords";
  public showDeletedToolTipKey: string = "showDeletedRecords";
  public showNewDeletedToolTipKey: string = "showDeletedNewRecords";
  public allowEdit: boolean = false;
  public myAccess: MyAccess = null;
  public strings: {} = {};
  public tabulatorLangObject: {} = {};

  constructor(
    private toastService: ToastService,
    private tableService: TableService,
    private modalService: ModalService,
    private tenantService: TenantService,
    private displayService: DisplayService,
    private adminService: AdminService,
    private stringLocalizerService: StringLocalizerService) {
    window.moment = moment;
    this.stringLocalizerService.strings.subscribe(s => {
      if (s) {
        this.strings = this.stringLocalizerService.getSection("tables");
        this.tabulatorLangObject = this.stringLocalizerService.getTabulatorLanguageObject();
      }
    })
  }

  async ngOnChanges() {
    if (this.tableInfo != null) {
      this.allowEdit = false;
      this.adminService.$myAccess.subscribe(myAccess => {
        if(myAccess != null){
          this.myAccess = myAccess;
          this.allowEdit = false;
          var dataSetAccess = this.myAccess.dataSets.find(d => d.dataSetId == this.tableInfo.id)
          if(dataSetAccess != null && (dataSetAccess.create || dataSetAccess.delete || dataSetAccess.update)){
            this.allowEdit = true;
          }
        }
      });
      var serviceTable = this.tableService.selectedTable;
      //or check it here
      var table: any = {};
      Object.assign(table, serviceTable);
      if (typeof Worker !== 'undefined') {
        // Create a new
        const worker = new Worker(new URL('./worker.worker', import.meta.url));
        worker.onmessage = ({ data }) => {
          this.schema = table.schema;
          this.tabHeaders = this.buildHeaders(table.schema, table.id);
          this.tabRows = data;
          this.redraw();
          this.drawn = true;
          worker.terminate();
        };
        var obj = JSON.parse(JSON.stringify(serviceTable));
        worker.postMessage(obj);
      } else {
        // Web workers are not supported in this environment.
        // You should add a fallback so that your program still executes correctly.
      }
    } else {
      this.displayService.setSelectedSpanText("");
    }
  }
  
  // draws the table and sets up row/cell/column/table stylizations
  private drawTable(): void {
    if (this.tab != null) {
      this.tab.remove();
      this.tab = null;
    }
    this.tab = document.createElement("div");
    const callback = this.editRow.bind(this);
    var tableId = this.tableId;
    this.table = new Tabulator(this.tab, {
      rowFormatter: this.rowStateFormatter,
      cellClick: function (_e, cell) {
        if (!cell.valueAtLoad) {
          if (cell._cell.value === null) {
            cell._cell.value = "";
          }
          cell.valueAtLoad = cell._cell.value;
        }
      },
      cellEdited: function (cell) { //track whenever a cell is edited
        var row = cell._cell.row;
        var columnName = cell.getColumn()._column.field;
        var rowState: Number = row.data["rowState"];

        //new Value is different from original value;
        if (cell._cell.value != cell._cell.initialValue) {
          if (!row.changedCells) {
            row.changedCells = [];
            for (let cell of row.cells) {
              if (cell.value != cell.initialValue) {
              }
            }
          }
          row.changedCells.push(columnName);
        }
        //new Value is same as original value;
        else {
          if (row.changedCells) {
            if (row.changedCells.find(f => f == columnName)) {
              row.changedCells.pop(columnName);
            }
          }
        }

        switch (rowState) {
          case 0:
          case 1:
            if (row.changedCells.length >= 1) { //If row has changes
              if (row.data["rowState"] != 2) { //if row has changes and is original row
                row.data["rowState"] = 3;
                row.getElement().style.backgroundColor = "#A6A6DF";
              }
            }
            break;
          case 2:
            break;
          case 3:
            if (row.changedCells.length == 0) { //If row has changes
              if (row.data["rowState"] != 2) { //if row has changes and is original row
                row.data["rowState"] = 1;
                row.getElement().style.backgroundColor = "";
              }
            }
            break;
          case 4:
            break;
          case 5:
            break;
        }
        callback(row, tableId);
      },
      layout: "fitDataStretch",
      movableColumns: true,
      height: "100%",
      pagination: "local",
      paginationSize: 25,
      paginationSizeSelector: [25, 50, 100],
      data: this.tabRows,
      columns: this.tabHeaders,
      locale: this.stringLocalizerService.currentCulture,
      langs: this.tabulatorLangObject
    });
    this.updateFilters();
    document.getElementById(`${this.tableId}`).appendChild(this.tab);
    this.displayService.setSelectedSpanText(this.schema.alias);
  }

  redraw() {
    this.drawTable();
  }

  // changes the color of a row if one of its cells were edited, and different from the original value at load
  rowStateFormatter = function (row) {
    var data = row.getData();
    if (data.rowState == 2) {
      row.getElement().style.backgroundColor = "#B8FFCC";
    }
    if (data.rowState == 3) {
      row.getElement().style.backgroundColor = "#A6A6DF";
    }
    if (data.rowState == 4) {
      row.getElement().style.backgroundColor = "#FFBAAB";
    }
    if (data.rowState == 5) {
      row.getElement().style.backgroundColor = "#FF8FD2";
    }
  }

  //creates headers and sort/editing type for cells under the given header.
  buildHeaders(schema: TableSchema, tableId: string): any[] {
    var headers: any[] = [];
    headers.push({
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      headerHozAlign: "center",
      hozAlign: "center",
      titleFormatterParams: {
        rowRange: "active"
      },
      headerSort: false, cellClick: function (e, cell) {
        cell.getRow().toggleSelect();
      }
    });
    schema.columns.forEach(c => {
      var h: any = {};
      h.title = c.alias;
      h.field = c.columnName;
      h.headerFilter = true;


      switch (c.columnDataType) {

        case 1: { //string
          h.headerFilter = "input";
          h.accessorDownload = this.nullToEmptyString;
          h.editable = this.allowEdit;
          break;
        }
        case 2: { //integer
          h.accessorDownload = this.nullToEmptyString;
          h.editable = this.allowEdit;
          break;
        }
        case 3: { //decimal
          h.accessorDownload = this.nullToEmptyString;
          h.editable = this.allowEdit;
          break;
        }
        case 4: { //boolean
          h.headerFilter = "tickCross";
          h.headerFilterParams = { "tristate": true };
          h.editable = false;
          break;
        }
        case 5: { //date
          h.headerFilter = "input";
          h.headerFilterFunc = this.dateHeaderFilterFunction;
          h.headerFilterLiveFilter = "true";
          h.sorter = "date";
          h.editable = this.allowEdit;
          break;
        }
      }  

      const dataSetAccess = this.tenantService.myAccess.dataSets.find(dataSet => dataSet.dataSetId === tableId);
      // sets what buttons are shown base on permission
      this.addRowShow = dataSetAccess.create;
      this.duplicateSelectedRowsShow = dataSetAccess.create;
      this.deleteSelectedRowsShow = dataSetAccess.delete;
      this.undeleteSelectedRowsShow = dataSetAccess.delete;
      this.editSelectedRowsShow = dataSetAccess.update;
      var tableEdit= this.allowEdit
      if (dataSetAccess.update == true || dataSetAccess.create == true || dataSetAccess.delete == true) {
        this.saveShow = true;
      } else {
        this.saveShow = false;
      }
      if (dataSetAccess?.update || dataSetAccess?.create) {
        //set editors
        if (c.listItems.length > 0) {
          h.editor = "select";
          h.editorParams = { values: c.listItems };
        } else {
          switch (c.columnDataType) {
            case 1: { //string
              h.editor = "input";
              break;
            }
            case 2: { //integer
              h.editor = "number";
              h.editorParams =
              {
                step: 1
              };
              h.formatter = this.integerFormatter;
              break;
            }
            case 3: { //decimal
              h.editor = "number";
              h.editorParams = {
                step: 1 / Math.pow(10, c.decimals)
              };
              break;
            }
            case 4: { //boolean
              h.editable = false;
              h.formatter = "tickCross";
              h.hozAlign = "center";
              h.formatterParams = {
                allowTruthy: true,
              },      
              h.cellClick = function (e, cell,) {
                  if(tableEdit){
                    cell.setValue(!cell.getValue());
                }
              }
              break;
            }
            case 5: { //date
              h.editor = this.dateEditorV2;
              h.formatter = this.dateFormatter;
              h.accessorDownload = this.downloadDate;
              break;
            }

            default: {
              break;
            }
          }
        }
      } else if (dataSetAccess?.read) {
        if (c.listItems.length <= 0) {
          switch (c.columnDataType) {
            case 4: { //boolean
              h.formatter = "tickCross";
              h.hozAlign = "center";
              h.formatterParams = {
                allowTruthy: true,
              }
              break;
            }
            case 5: { //date
              h.formatter = this.dateFormatter;
              h.accessorDownload = this.downloadDate
              break;
            }

            default: {
              break;
            }
          }
        }
      } else {
      }

      //set sorters
      h.headerSortTristate = true;
      switch (c.columnDataType) {
        case 1: { //string
          h.sorter = "string";
          break;
        }
        case 2: { //integer
          h.sorter = "number";
          break;
        }
        case 3: { //decimal
          h.sorter = "number";
          break;
        }
        case 4: { //boolean
          h.sorter = "boolean";
          break;
        }
        case 5: { //date
          h.sorter = this.dateSorter;
          break;
        }
        default: {
          break;
        }
      }
      headers.push(h);
    });
    return headers;
  }

  //renders Invalid Request if decimal is used in integer
  integerFormatter(cell, formatterParams, onRendered) {
    var cellValue = cell.getValue();
    if (cellValue % 1 === 0) {
      cell.getElement().style.color = "#000000";
    } else {
      cell.getElement().style.color = "#FF0000";
      return cellValue = "INVALID INTEGER";
    }
    return cell.getValue(); //return the contents of the cell;
  }

  //downloads a table as CSV to the browser
  downloadCsv() {
    this.table.download("csv", `${this.tableInfo.alias}.csv`);
  }

  //downloads a table as json to the browser
  downloadJson() {
    this.table.download("json", `${this.tableInfo.alias}.json`);
  }

  //When downloading turns null values into empty strings
  nullToEmptyString = function (value, data, type, params, column) {
    return value || ""; //return the new value for the cell data.
  }

  // when downloading changes the date to common format. 
  downloadDate = function (value, field, data, type, params, column) {
    if (value == null || value == "" || value == "(mm/dd/yyyy)") {
      return value || "";
    } else {
      // var momentValue = moment(value, "YYYY-MM-DDTHH:mm:ss");
      // var reformattedMomentValue = momentValue.format("MM/DD/YYYY")
      // return reformattedMomentValue || "";
    }
  }

  dateHeaderFilterFunction = function (headerValue, rowValue, rowData, filterParams) {
    //headerValue - the value of the header filter element
    //rowValue - the value of the column in this row
    //rowData - the data for the row being filtered
    //filterParams - params object passed to the headerFilterFuncParams property
    if (rowValue) {
      if (headerValue != "") {
        let newHeader = headerValue.replaceAll(/[-_.]/g, "/");
        let simpleRowValue = moment(rowValue, "YYYY-MM-DDTHH:mm:ss");
        var reformattedMomentValue = simpleRowValue.format("MM/DD/YYYY");
        if (reformattedMomentValue.includes(newHeader)) {
          let filterRowValue = rowValue;
          return filterRowValue;
        }
      }
    }
    return this.filterRowValue;
  }

  dateEditorV2 = function (cell, onRendered, success, cancel, editorParams) {
    //cell - the cell component for the editable cell
    //onRendered - function to call when the editor has been rendered
    //success - function to call to pass the successfuly updated value to Tabulator
    //cancel - function to call to abort the edit and return to a normal cell
    //editorParams - params object passed into the editorParams column definition property

    //create and style editor
    var editor = document.createElement("input");

    editor.setAttribute("type", "input");

    //create and style input
    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    //Set value of editor to the current value of the cell
    var getValue = cell.getValue();
    if (getValue == null || getValue == "" || getValue == "INVALID DATE") {
      editor.value = "(mm/dd/yyyy)";
      editor.setSelectionRange(0, editor.value.length);
    }
    else {
      var editorValue = moment(getValue, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY");
      editor.value = editorValue;
    }

    //set focus on the select box when the editor is selected (timeout allows for editor to be added to DOM)
    onRendered(function () {
      editor.focus();
      editor.style.height = "100%";
    });

    //when the value has been set, trigger the cell to update
    function successFunc() {
      var value = editor.value;
      if (value == null || value == "(mm/dd/yyyy)" || value == "") {
        success(null);
      }
      else {
        var testMoment = moment(value, "MM-DD-YYYY");
        var formatMoment = testMoment.format("YYYY-MM-DDTHH:mm:ss");
        if (formatMoment == "Invalid date") {
          cell.getElement().style.color = "#cc3300";
          success("INVALID DATE");
        } else {
          cell.getElement().style.color = "";
          success(formatMoment);
        }
      }
    }

    editor.addEventListener("blur", successFunc);
    editor.addEventListener("keydown", function (e) {
      if (e.key == "Enter") {
        successFunc();
      }

      if (e.key == "Escape") {
        cancel();
      }
    });

    //return the editor element
    return editor;
  };

  dateFormatter = function (cell, formatterParams, onRendered) {
    var value = cell.getValue();
    if (value == null || value == "" || value == "(mm/dd/yyyy)") {
      var style = cell.getElement().style;
      cell.getElement().style.color = "#e0e0eb"
      return "(mm/dd/yyyy)";
    }
    else {
      var momentValue = moment(value, "YYYY-MM-DDTHH:mm:ss");
      var reformattedMomentValue = momentValue.format("MM/DD/YYYY");
      if (reformattedMomentValue != "Invalid date") {
        return reformattedMomentValue;
      }
      else {
        return "INVALID DATE";
      }
    }
  };

  dateSorter = function (a, b, aRow, bRow, column, dir, sorterParams) {
    //a, b - the two values being compared
    //aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
    //column - the column component for the column being sorted
    //dir - the direction of the sort ("asc" or "desc")
    //sorterParams - sorterParams object from column definition array
    var calcDate = function (date) {
      var momentValue = moment(date, "YYYY-MM-DDTHH:mm:ss");
      if (momentValue.isValid()) {
        var shortened = date.split("T")[0].replaceAll("-", "");
        var shortenedNumber = parseInt(shortened);
        return shortenedNumber;
      } else {
        if (date == null || date == "") {
          return 0;
        } else {
          return -1;
        }
      }
    };

    var aNumber = calcDate(a);
    var bNumber = calcDate(b);

    return aNumber - bNumber; //you must return the difference between the two values
  };


  // adds an empty row to the table, and assigns any specified default values to their respective cells.  
  addRow() {
    var newRow = {};
    this.schema.columns.forEach(c => {
      if (c.columnDataType == 4) {
        newRow[c.columnName] = this.parseBoolean(c.defaultValue);
      } else {
        newRow[c.columnName] = c.defaultValue
      }
    });
    newRow["id"] = this.generateUUID();
    // newRows didn't have an index so delete could getIndex to delete. 
    newRow["index"] = newRow["id"];
    newRow["rowState"] = 2;
    this.table.addRow(newRow);
    this.tableService.addTableRow(newRow, this.tableInfo["id"]);
    this.updateFilters();
    this.table.scrollToRow(newRow["index"], "top", false);
  }

  // creates a copy of any selected rows, assigns those rows new ID's, and adds those rows to the table
  duplicateSelectedRows() {
    var rows = this.table.getSelectedData();
    rows.forEach(r => {
      if (r.rowState != 4 && r.rowState != 5) {
        var newRow = {};
        this.schema.columns.forEach(c => {
          newRow[c.columnName] = r[c.columnName];
        });
        newRow["id"] = this.generateUUID();
        newRow["index"] = newRow["id"];
        newRow["rowState"] = 2;
        this.table.addRow(newRow);
        this.tableService.addTableRow(newRow, this.tableInfo["id"]);
        this.updateFilters();
      }
      this.table.scrollToRow(newRow["index"], "top", false);
    });
  }

  // sets any selected row to a state of "5", which results in a red highlighting. these records are filtered out by default
  deleteSelectedRows() {
    var rows = this.table.getSelectedRows();

    rows.forEach(r => {
      //Change "New" to "New Deleted"
      var deletedIndexes: string[] = [];
      switch (r._row.data["rowState"]) {
        case 0:
        case 1:
        case 3:
          r._row.data["rowState"] = 4;
          r.getElement().style.backgroundColor = "#FFBAAB";
          this.editRow(r._row, this.tableInfo["id"]);
          let activeRowsX = this.table.rowManager.activeRows;
          let errorIdX = activeRowsX.indexOf(activeRowsX.find(e => e.data.id == r._row.data.id));

          this.updateFilters();

          let activeRows = this.table.rowManager.activeRows;

          if (activeRowsX.length - 1 != errorIdX) {
            let locationId = activeRows[errorIdX].data.id;
            this.table.scrollToRow(locationId, "top", true);
            break;
          } else {
            let locationId = activeRows[errorIdX - 1].data.id;
            this.table.scrollToRow(locationId, "top", true);
            break;
          }
        case 2:
          r._row.data["rowState"] = 5;
          r.getElement().style.backgroundColor = "#FF8FD2";
          this.editRow(r._row, this.tableInfo["id"]);
          let activeRowsNewBefore = this.table.rowManager.activeRows;
          let errorIdNew = activeRowsNewBefore.indexOf(activeRowsNewBefore.find(e => e.data.id == r._row.data.id));

          this.updateFilters();

          let activeRowsNew = this.table.rowManager.activeRows;

          if (activeRowsNew.length != errorIdNew) {
            let locationId = activeRowsNew[errorIdNew].data.id;
            this.table.scrollToRow(locationId, "top", true);
            break;
          } else {
            let locationId = activeRowsNew[errorIdNew - 1].data.id;
            this.table.scrollToRow(locationId, "top", true);
            break;
          }
      }
    });
  }

  undeleteSelectedRows() {
    var rows = this.table.getSelectedRows();
    rows.forEach(r => {
      //Change "New Deleted" to "New"
      if (r._row.data["rowState"] == 5) {
        r._row.data["rowState"] = 2;
        r.getElement().style.backgroundColor = "#B8FFCC";
      }
      else if (r._row.data["rowState"] == 4) {
        var changedCells = [];
        //Change "Deleted" to "Edited"
        var initialValues = this.tableService.getInitialValues(r._row.data.id, this.tableInfo.id);
        for (const [key, value] of Object.entries(r._row.data)) {
          if (key != "index" && !key.startsWith("_") && key != "rowState") {
            if (initialValues[key] != r._row.data[key]) {
              changedCells.push(key);
            }
          }
        }
        if (changedCells.length >= 1) {
          r._row.changedCells = changedCells;
        }
        if (r._row.changedCells && r._row.changedCells.length >= 1) {
          r._row.data["rowState"] = 3;
          r.getElement().style.backgroundColor = "#A6A6DF";
        } else { //Change Deleted to "Original"
          r._row.data["rowState"] = 1;
          r.getElement().style.backgroundColor = "";
        }
      }
    });
    this.updateFilters();
    rows.forEach(e => {
      this.table.scrollToRow(e._row.data.index, "top", false)
    });
  }

  private generateUUID(): string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public editRow(rowData: any, tableId: string) {
    this.tableService.editRow(rowData, this.tableInfo["id"]);
  }

  public async saveTableChanges() {
    var testRows = this.table.getRows();
    var rows = this.table.getRows()
      // needed to switch <=5 to include newDelete record
      .filter(r => r._row.data.rowState >= 2 && r._row.data.rowState <= 5);
    await this.tableService.updateTableRows(rows, this.tableInfo.id).then(success => {
      if (success) {
        var deletedIndexes: string[] = [];
        var upsertedIndexes: string[] = [];
        rows.forEach(row => {
          switch (row._row.data["rowState"]) {
            case 2:
              upsertedIndexes.push(row.getIndex());
              break;
            case 3:
              upsertedIndexes.push(row.getIndex());
              break;
            case 4:
              deletedIndexes.push(row.getIndex());
              break;
            case 5:
              //added for new record delete
              deletedIndexes.push(row.getIndex());
              break;
          }

        });
        this.commitUpsertedRows(upsertedIndexes);
        this.commitDeletedRows(deletedIndexes);
        this.commitTableLog();
        this.toastService.showSuccessToast('', 'Saving Complete!');
      } else {
        console.error("failed to save data");
        this.toastService.showErrorToast('Save Failed', '');
      }
    })
      .catch(error => {
        console.error("failed to save data");
        this.toastService.showErrorToast('Save Failed', '')
      });
  }

  private commitUpsertedRows(newIndexes: string[]) {
    newIndexes.forEach(index => {
      var row = this.table.getRow(index);
      row._row.data["rowState"] = 1;
      row.getElement().style.backgroundColor = "";
      row._row.changedCells = [];
      row._row.cells.forEach(cell => {
        cell.initialValue = cell.value;
        cell.oldValue = cell.value;
      });
    });
  }

  private commitDeletedRows(deletedIndexes: string[]) {
    deletedIndexes.forEach(index => {
      this.table.deleteRow(index);
    });
  }

  // Connect with User Service to output array of change cells with user info.
  private commitTableLog() {
  }

  private parseBoolean(b: string) {
    if (b == null || b == "") {
      return false;
    }
    var bLower = b.toLowerCase();
    var trueValues = ["true", "1", "t", "yes", "y"];
    var falseValues = ["false", "0", "f", "no", "n"];
    var trueFilter = trueValues.find(tv => tv === bLower);
    if (trueFilter) {
      return true;
    }
    var falseFilter = falseValues.find(fv => fv === bLower);
    if (falseFilter) {
      return false;
    }
    return false;
  }



  // FILTER CONTROLS

  // toggle visibility of unedited, non-new records
  public toggleOriginalRecordsFilter() {
    this.showOriginal = !this.showOriginal;
    this.showOriginalToolTipKey = this.showOriginal ? "hideOriginalRecords" : "showOriginalRecords";
    this.updateFilters();
  }

  // toggle visibility of edited, non-new records 
  public toggleEditedOriginalRecordsFilter() {
    this.showChanged = !this.showChanged;
    this.showChangedToolTipKey = this.showChanged ? "hideEditedOriginalRecords" : "showEditedOriginalRecords";
    this.updateFilters();
  }

  // toggle visibility of newly created records
  public toggleNewRecordsFilter() {
    this.showNew = !this.showNew;
    this.showNewToolTipKey = this.showNew ? "hideNewRecords" : "showNewRecords";
    this.updateFilters();
  }

  // toggle visibility of deleted, non-new records
  public toggleDeletedRecordsFilter() {
    this.showDeleted = !this.showDeleted;
    this.showDeletedToolTipKey = this.showDeleted ? "hideDeletedRecords" : "showDeletedRecords";
    this.updateFilters();
  }

  // toggle visibility of deleted, newly created records.
  public toggleDeletedNewRecordsFilter() {
    this.showNewDeleted = !this.showNewDeleted;
    this.showNewDeletedToolTipKey = this.showNewDeleted ? "hideDeletedNewRecords" : "showDeletedNewRecords";
    this.updateFilters();
  }

  // triggers table filters to go into effect
  private updateFilters() {
    var rowStateFilter = [];
    if (this.showOriginal) { rowStateFilter.push(0); rowStateFilter.push(1) }
    if (this.showNew) { rowStateFilter.push(2) }
    if (this.showChanged) { rowStateFilter.push(3) }
    if (this.showDeleted) { rowStateFilter.push(4) }
    if (this.showNewDeleted) { rowStateFilter.push(5) }
    if (rowStateFilter.length == 0) { rowStateFilter.push(-1) }
    this.table.setFilter("rowState", "in", rowStateFilter);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
