<mat-drawer-container class="container" autosize>
    <mat-drawer #drawer (openedChange)="onOpenedChange($event)" class="sidenav" mode="push" opened>
        <p class="drawer-title">{{strings["title"]}}</p>
        <p class="drawer-subtitle">{{integrationName}}</p>
        <mat-selection-list [multiple]="false">
            <mat-list-option (click)="setDataStudioTitle(date.longDate)" (click)="getFileNames(date.utcDate)" (click)="drawer.toggle()" class="date-item" *ngFor="let date of fileDates" [value]="date.longDate">
                {{date.longDate}}
                <mat-divider></mat-divider>
            </mat-list-option>
        </mat-selection-list>
    </mat-drawer>

    <div class="sidenav-content">
        <button mat-button class="sidenav-toggle-open" (click)="drawer.toggle()">
            <img  src="../../assets/images/icons/FillchevronRight.svg" [ngClass]="{ 'chevron-left': isDrawerOpen==false, 'chevron-right': isDrawerOpen==true }">
        </button>
        <div class="data-lake">
            <div mat-subheader>{{strings["subTitle"]}}</div>
            <div *ngIf="noFiles" class="noFilesFound">{{strings["noDataOutputs"]}}</div>
            <div *ngIf="toggleSearchCoontainer"  class="toggle-search-container">
            <mat-checkbox class="toggle" aria-label="Select All" [checked]="isChecked()" [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
                {{strings["toggleAll"]}}
            </mat-checkbox>
            <input type="text" [placeholder]="strings['search']" [(ngModel)]="searchFileList"
            (input)="filterFileList()" class="search-field">
            </div>
            <div class="file-list-item" *ngFor="let file of filteredFiles">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggle(file, $event) : null" [checked]="exists(file)">
                    <img src="../../../assets/images/excel-download-icon.svg" *ngIf="file.extension == 'xlsx'">
                    <img src="../../../assets/images/csv-download-icon.svg" *ngIf="file.extension == 'csv'">
                    <img src="../../../assets/images/json-download-icon.svg" *ngIf="file.extension == 'json'">
                    <img src="../../../assets/images/txt-download-icon.svg" *ngIf="file.extension == 'txt'">
                    <img src="../../../assets/images/pdf.png" *ngIf="file.extension == 'pdf'">
                    <img src="../../../assets/images/xml-file.png" *ngIf="file.extension == 'xml'">
                    <img src="../../../assets/images/doc.png" *ngIf="file.extension == 'docx'">
                    <img src="../../../assets/images/unknown-download-icon.svg" *ngIf="file.extension != 'docx' && file.extension !='pdf' && file.extension !='xlsx' && file.extension !='csv' && file.extension !='txt' && file.extension !='json' && file.extension !='xml'">
                </mat-checkbox>
                <div class="file-name" (click)="downloadFile(file)">{{file.name}}</div>
            </div>
            <button *ngIf="toggleSearchCoontainer"(click)="downloadAllChecked()" class="download-button">{{strings["download"]}}</button>
        </div>
    </div>
</mat-drawer-container>