import * as moment from 'moment';
import { DataLakeFileListing } from './data-lake-file-listing';

export class DataLakeFileDateListing {
    name: string;
    utcDate: string
    filesListing: DataLakeFileListing[];
    longDate: string;

    constructor(name: string) {
        this.name = name;
        this.utcDate = name;
        var lastChar = (this.utcDate.substring(this.utcDate.length-1,this.utcDate.length))
        if(lastChar == "Z") {
            this.longDate = moment(new Date(this.utcDate)).format("MMM DD YYYY hh:mm A");
        } else {
            this.longDate = this.utcDate;
        }
        this.filesListing = [];
    }
}
