import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventTypes } from '../models/event-types';
import { ToastEvent } from '../models/toast-event';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastEvents: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();
  public $toastDisplay: BehaviorSubject<boolean>;

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
    this.$toastDisplay = new BehaviorSubject<boolean>(false)
  }

  /**
   * Show success toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showSuccessToast(title: string, message: string) {
    // this.$toastDisplay.next(true);
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Success,
    });
  }

  /**
   * Show info toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showInfoToast(title: string, message: string) {
    // this.$toastDisplay.next(true);
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Info,
    });
  }

  /**
   * Show warning toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showWarningToast(title: string, message: string) {
    this.$toastDisplay.next(true);
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Warning,
    });
  }

  /**
   * Show error toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showErrorToast(title: string, message: string) {
    // this.$toastDisplay.next(true);
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Error,
    });
  }
  /**
   * Show error toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showSingleErrorToast(title: string, message: string) {
    // this.$toastDisplay.next(true);
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Error,
    });
  }
}