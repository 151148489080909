import {Permissions} from './permissions/permissions'
export class Role {
    public id:string;
    public name?: string;
    public assignedDate: string;
    public expirationDate: string;
    public assignedBy: string;
    public enable?: boolean = false;
    public permissions?: Permissions;
    public tenantId?: string;
    private stateUpdated: boolean = null;

    constructor(id: string, assignedDate: string, expirationDate: string, assignedBy: string){
        this.id = id;
        this.assignedBy = assignedDate;
        this.expirationDate = expirationDate;
        this.assignedBy = assignedBy;
    }
}
