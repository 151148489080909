import { Component, Input, OnInit } from '@angular/core';
import { IntegrationStatus } from 'src/app/models/integration-status';
import * as moment from 'moment'
import { DurableFunctionService } from 'src/app/services/durable-function.service';

@Component({
  selector: 'app-integration-status',
  templateUrl: './integration-status.component.html',
  styleUrls: ['./integration-status.component.css']
})
export class IntegrationStatusComponent implements OnInit {

  @Input() status: IntegrationStatus;
  
  public isUnknown: boolean = false;
  public isRunning: boolean = false;
  public isCompleted: boolean = false;
  public isContinuedAsNew: boolean = false;
  public isFailed: boolean = false;
  public isCanceled: boolean = false;
  public isTerminated: boolean = false;
  public isPending: boolean = false;
  public date: string;
  public isDeleting: boolean = false;
  public dayOfWeek: number = null;

  constructor(private durableFunctionService: DurableFunctionService) { }

  // checks numerical values to determine what a specific instances state is. state will determine which image is shown. 
  // see lines 2-10 on integration-status.component.html
  ngOnInit(): void {
    this.date = moment(new Date(this.status.createdTime)).format("MMM DD YYYY hh:mm A");
    this.dayOfWeek = new Date(this.status.createdTime).getDay() + 1;
    switch(this.status.runtimeStatus){
      case -1:
        this.isUnknown = true;
        break;
      case 0:
        this.isRunning = true;
        break;
      case 1:
        this.isCompleted = true;
        break;
      case 2:
        this.isContinuedAsNew = true;
        break;
      case 3:
        this.isFailed = true;
        break;
      case 4:
        this.isCanceled = true;
        break;
      case 5:
        this.isTerminated = true;
        break;
      case 6:
        this.isPending = true;
        break;
      default:
    }
  }

  // requests durable function service to cancel a current running instance
  terminateInstance(){
    this.durableFunctionService.terminateInstance(this.status.instanceId, this.status.alias, this.status.name);
  }

  // requests durable function service to remove a specified instance from the history
  deleteInstance(){
    this.isDeleting = true;
    this.durableFunctionService.deleteInstance(this.status.instanceId, this.status.alias, this.status.name);
  }
}
