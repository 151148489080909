import { DataLakeRunDataDateListing } from './data-lake-run-data-date-listing';

export class DataLakeRunDataSolutionListing {
    public name: string;
    public dateListings: DataLakeRunDataDateListing[];

    constructor(name: string){
        this.name = name;
        this.dateListings = [];
    }
}
