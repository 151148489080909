<div class="sidenav-content">
    <app-integration-history class="run-history" *ngIf="integrationSelected"></app-integration-history>
    <mat-tab-group mat-stretch-tabs="false" class="process-options-container">
        <mat-tab *ngFor="let integrationAction of integrationActions | async">
            <ng-template matTabLabel>
                <span>{{integrationAction.name}}</span>
            </ng-template>
            <div>
                <div *ngFor="let param of integrationAction.parameters">
                    <div *ngIf="param.prompt===true" class="param">
                        <p>{{param.alias}}: </p>
                        <mat-form-field *ngIf="param.dataType=='0'" color="accent" appearance="fill">
                            <mat-label>{{param.alias}}</mat-label>
                            <input matInput [(ngModel)]="param.defaultValue">
                        </mat-form-field>

                        <mat-form-field *ngIf="param.dataType=='1'" color="accent" appearance="fill">
                            <mat-label>{{param.alias}}</mat-label>
                            <input matInput [(ngModel)]="param.defaultValue">
                        </mat-form-field>

                        <mat-form-field *ngIf="param.dataType=='2'" color="accent" appearance="fill">
                            <mat-label>{{param.alias}}</mat-label>
                            <input matInput [(ngModel)]="param.defaultValue" type="number" (keypress)="onlyNumberKey($event)">
                        </mat-form-field>

                        <mat-form-field *ngIf="param.dataType=='3'" color="accent" appearance="fill">
                            <mat-label>{{param.alias}}</mat-label>
                            <input matInput [(ngModel)]="param.defaultValue" type="number">
                        </mat-form-field>

                        <mat-checkbox class="checkbox" [(ngModel)]="param.defaultValue" *ngIf="param.dataType=='4'">
                        </mat-checkbox>

                        <mat-form-field *ngIf="param.dataType=='5'" color="accent" appearance="fill">
                            <mat-label>mm/dd/yyyy</mat-label>
                            <input matInput [matDatepicker]="picker1" [(ngModel)]="param.defaultValue">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field *ngIf="param.dataType=='Spinner'" color="accent" appearance="fill">
                            <mat-label>mm/dd/yyyy</mat-label>
                            <input matInput [matDatepicker]="picker1">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="process-button-container">
                    <button class="process-button" mat-raised-button color="primary" [disabled]="actionDisabled" (click)="runIntegrationAction(integrationAction.name,integrationAction.parameters)">{{strings["process"]}}</button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>