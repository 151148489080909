import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  public $integrationNames: BehaviorSubject<string[]> 
  public $integrationNameSelected: BehaviorSubject<string>
  
  //Get list of Integration names, and sub-actions within the integration
  constructor(private tenantService: TenantService) {
    this.$integrationNames = new BehaviorSubject<string[]>([]);
    this.$integrationNameSelected = new BehaviorSubject<string>(null);
    this.tenantService.$tenant.subscribe(tenantConfiguration => {
      if (tenantConfiguration != null){
        if (tenantConfiguration.integrations.length >= 1){
          this.$integrationNames.next(tenantConfiguration.integrations.map(i => i.name));
          this.$integrationNameSelected.next(tenantConfiguration.integrations[0].name);
        }
      }
    });
  }

  public selectIntegration(integrationName: string) {
    this.$integrationNameSelected.next(integrationName);
  }
}
