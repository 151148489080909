import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IntegrationStatus } from 'src/app/models/integration-status';
import { ActionService } from 'src/app/services/action.service';
import { DurableFunctionService } from 'src/app/services/durable-function.service';

@Component({
  selector: 'app-integration-history',
  templateUrl: './integration-history.component.html',
  styleUrls: ['./integration-history.component.css']
})
export class IntegrationHistoryComponent {

  public $history = new BehaviorSubject<IntegrationStatus[]>(null);
  public history = this.$history.asObservable();

  public $selectedIntegration = new BehaviorSubject<string>(null);
  public selectedIntegration = this.$selectedIntegration.asObservable();


  // watches history collection in durable function service. updates the rendered "state" of a previously
  // run or running instance when the values change. 
  constructor(private durableFunctionService: DurableFunctionService, private actionService:ActionService) { 
    this.actionService.$integrationNameSelected.subscribe(integrationName => {
      if(integrationName != null) {
        this.$selectedIntegration.next(integrationName);
        // causes double integration history to download 
        this.durableFunctionService.loadIntegrationHistory(integrationName);
        var histories = this.durableFunctionService.getHistory(integrationName);
        this.$history = histories;
        this.$history.subscribe(h => {
          this.history = this.$history.asObservable();
        });
      }
    });
  }
}
