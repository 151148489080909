<div class="container">
    <div [id]=tableId class="tabulator-container"></div>
    <div class="table-operations">
        <div *ngIf="allowEdit" class="row-operations">
            <img  class="add-row-icon" src="../../assets/images/icons/Filladd.svg"
                [matTooltip]="strings['addNewRecord']" mat-stroked-button matTooltipPosition="above" (click)="addRow()">
            <img  class="copy-row-icon" src="../../assets/images/icons/Fillcopy.svg"
                [matTooltip]="strings['cloneSelectedRecord']" mat-stroked-button matTooltipPosition="above"
                (click)="duplicateSelectedRows()">
            <img  class="delete-row-icon" src="../../assets/images/icons/FilllargeX.svg"
                [matTooltip]="strings['deleteSelectedRecord']" mat-stroked-button matTooltipPosition="above"
                (click)="deleteSelectedRows()">
            <img class="undelete-row-icon" src="../../assets/images/icons/Fillundo.svg"
                [matTooltip]="strings['undeleteSelectedRecord']" mat-stroked-button matTooltipPosition="above"
                (click)="undeleteSelectedRows()">
            <img src="../../assets/images/icons/Fillsave.svg" 
                class="material-icons-outlined save-table-icon" [matTooltip]="strings['saveChanges']" mat-stroked-button
                matTooltipPosition="above" (click)="saveTableChanges()">
        </div>
        <div class="row-filters" *ngIf="allowEdit">
            <button matTooltip="{{strings[showOriginalToolTipKey]}}" mat-stroked-button
                matTooltipPosition="above" [ngClass]="{'original enabled': showOriginal, 'disabled': !showOriginal}"
                (click)="toggleOriginalRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button  matTooltip="{{strings[showChangedToolTipKey]}}"
                mat-stroked-button matTooltipPosition="above"
                [ngClass]="{'changed enabled': showChanged, 'disabled': !showChanged}"
                (click)="toggleEditedOriginalRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button  matTooltip="{{strings[showNewToolTipKey]}}" mat-stroked-button
                matTooltipPosition="above" [ngClass]="{'new enabled': showNew, 'disabled': !showNew}"
                (click)="toggleNewRecordsFilter()">
                <img *ngIf="addRowShow" src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button  matTooltip="{{strings[showDeletedToolTipKey]}}"
                mat-stroked-button matTooltipPosition="above"
                [ngClass]="{'deleted enabled': showDeleted, 'disabled': !showDeleted}"
                (click)="toggleDeletedRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
            <button matTooltip="{{strings[showNewDeletedToolTipKey]}}"
                mat-stroked-button matTooltipPosition="above"
                [ngClass]="{'new-deleted enabled': showNewDeleted, 'disabled': !showNewDeleted}"
                (click)="toggleDeletedNewRecordsFilter()">
                <img src="../../assets/images/icons/Fillfilter.svg">
            </button>
        </div>

        <div class="exports">
            <div>{{strings["csv"]}}: </div>
            <img class="csv-download-icon" src="../../assets/images/icons/FileDownload.svg" (click)="downloadCsv()"
                alt="download as csv file">

            <div>{{strings["json"]}}: </div>
            <img class="json-download-icon" src="../../assets/images/icons/FileDownload.svg"
                (click)="downloadJson()" alt="download as json file">
                
<!-- import CSV would be a feature that would allow the user to import a CSV file into the table. -->
        </div>
    </div>
</div>