import { DataLakeFileDateListing } from './data-lake-file-date-listing';

export class DataLakeFileSolutionListing {

    public name: string;
    public dateListings: DataLakeFileDateListing[];

    constructor(name: string){
        this.name = name;
        this.dateListings = [];
    }
}
