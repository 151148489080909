import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IntegrationDetails } from 'src/app/models/integration-details';
import { IntegrationAction } from 'src/app/models/integration-action';
import { DurableFunctionService } from 'src/app/services/durable-function.service';
import { BehaviorSubject } from 'rxjs';
import { ActionService } from 'src/app/services/action.service';
import { IntegrationActionParameter } from 'src/app/models/integration-action-parameter';
import { DisplayService } from 'src/app/services/display.service';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';


@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {
  @ViewChild("chevron", { read: ElementRef }) chevron: ElementRef;

  private $integrations = new BehaviorSubject<IntegrationDetails[]>([]);
  public $integrationActions = new BehaviorSubject<IntegrationAction[]>(null);

  public integrationSelected: string = null;
  public processRunning: boolean = false;
  public processFinished: boolean = false;

  public actionDisabled: boolean = false;

  public isDrawerOpen: boolean = false;
  public strings: {} = {};

  get integrationActions() {
    return this.$integrationActions.asObservable();
  }

  constructor(
    private durableFunctionService: DurableFunctionService,
    private actionService: ActionService,
    private displayService: DisplayService,
    private stringLocalizerService: StringLocalizerService) { 
      this.stringLocalizerService.strings.subscribe(s => {
        if(s){
          this.strings = this.stringLocalizerService.getSection("actions");
        }
      });
  }

  ngOnInit(): void {
    this.displayService.$selectedActionSolution.subscribe(solution => {
      if (solution != null) {
        this.displayService.setSelectedSpanText(solution);
        this.actionService.selectIntegration(solution);
        this.integrationSelected = solution;
        this.loadIntegrationActions(solution);
        this.durableFunctionService.loadIntegrationHistory(solution);
      }
    });
  }

  private parseActionVariables(integrationDetails: IntegrationDetails[]): IntegrationDetails[] {
    integrationDetails.forEach(i => {
    i.actions.forEach(a => {
        a.parameters.forEach(p => {
          if(p.dataType == 4){
            var stringValue = p.defaultValue.toLowerCase();
            p.defaultValue = (stringValue == "true" || stringValue == "t");
          }
        })
      })
    });
    return integrationDetails;
  }

  //retrieve collection of sub-actions in a given client integration
  private loadIntegrationActions(integrationName: string) {
    let x = this.durableFunctionService.listIntegrations();
    x = this.parseActionVariables(x);
    this.$integrationActions.next(x.find(i => i.name == integrationName).actions);
    this.displayService.setSelectedSpanText(this.integrationSelected); 
  }

  //call durable function service and trigger a POST to client integration
  public async runIntegrationAction(actionName: string, params: IntegrationActionParameter[]) {
    this.durableFunctionService.runIntegration(this.integrationSelected, actionName, params);
    this.actionDisabled = true;
    //Keep "Run" button disabled for 5 seconds after activating
    await this.delay(5000);
    this.actionDisabled = false;
  }

  //Alternative method to runIntegration, when a tenant is setup as a demo instead of belonging to a client. 
  //will check for a email address and send an email on completion

  async toggleSpinner() {
    this.processFinished = false;
    if (this.processRunning == false) {
      this.processRunning = true;
    }
    await this.delay(5000);
    if (this.processRunning == true) {
      this.processRunning = false;
    }
    this.processFinished = true;
  }

  delay(delay: number) {
    return new Promise(r => {
      setTimeout(r, delay);
    });
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onOpenedChange(event) {
    this.isDrawerOpen = !this.isDrawerOpen;
    if (!this.isDrawerOpen) {
      this.durableFunctionService.loadIntegrationHistory(this.integrationSelected);
    }
  }
}
