import { BehaviorSubject } from 'rxjs';
import { IntegrationStatus } from './integration-status';

export class SolutionHistory {
    public name: string;
    public statusListing: BehaviorSubject<IntegrationStatus[]>;

    public constructor(name: string){
        this.name = name;
        this.statusListing = new BehaviorSubject<IntegrationStatus[]>(null);
    }
}
