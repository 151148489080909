import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
// fetch('assets/config.json').then(async res => {
//   var response = await res.json();
//   const appConfig = new AppConfiguration(
//     response["environment"], 
//     response["oktaConfig"]);

//   platformBrowserDynamic([
//     { provide: AppConfiguration, useValue: appConfig }
//   ]).bootstrapModule(AppModule)
//     .catch(err => console.error(err));
// });
