import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StringLocalizerService {
  private defaultCulture: string = "en-US";
  public currentCulture: string = null;
  private supportedCultures = [
    "en-us",
    "fr-ca"
  ]
  public strings: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>(null);

  constructor(private http: HttpClient) {
    this.setLanguage(this.defaultCulture)
  }

  public async setLanguage(cultureCode: string) {
    cultureCode = cultureCode.toLowerCase();
    if (this.currentCulture != cultureCode) {
      if (!this.supportedCultures.includes(cultureCode)) {
        console.log(`Culture Code \"${cultureCode}\" is not supported. Defaulting to English-US.`)
        cultureCode = "en-us"
      }
      await this.http.get<{}>(`../assets/i18n/${cultureCode.split("-")[0]}.json`).toPromise()
        .then(c => {
          this.strings.next(c["data"]);
          this.currentCulture = cultureCode;
        })
        .catch(e => {
          console.log(`Error loading language file. Exception:${e}`)
        });
    }
  }

  public getSection(sectionName): any {
    let strings: {} = {};
    let resourceStrings = this.strings.value.filter(d => d["LabelName"].startsWith(sectionName));
    resourceStrings.forEach(s => {
      strings[s["LabelName"].split("-")[1]] = s["LabelValue"];
    });
    return strings;
  }

  public getTabulatorLanguageObject(): any{
    var langObject = {}
    var tabulatorSection = this.getSection("tabulator");
    var culture = tabulatorSection["culture"].toLowerCase();
    langObject[culture] = {};
    langObject[culture]["pagination"] = {};
    langObject[culture]["pagination"]["page_size"] = tabulatorSection["pageSize"];
    // langObject[culture]["pagination"]["page_title"] = this.tabulatorSection[""];
    langObject[culture]["pagination"]["first"] = tabulatorSection["first"];
    langObject[culture]["pagination"]["first_title"] = tabulatorSection["firstTitle"];
    langObject[culture]["pagination"]["last"] = tabulatorSection["last"];
    langObject[culture]["pagination"]["last_title"] = tabulatorSection["lastTitle"];
    langObject[culture]["pagination"]["prev"] = tabulatorSection["prev"];
    langObject[culture]["pagination"]["prev_title"] = tabulatorSection["prevPage"];
    langObject[culture]["pagination"]["next"] = tabulatorSection["next"];
    langObject[culture]["pagination"]["next_title"] = tabulatorSection["nextPage"];
    langObject[culture]["headerFilters"] = {};
    langObject[culture]["headerFilters"]["default"] = tabulatorSection["filterColumn"];
    return langObject;
  }
}