import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DisplayService } from 'src/app/services/display.service';
import { TableService } from 'src/app/services/table.service';

@Component({
  selector: 'app-data-studio',
  templateUrl: './data-studio.component.html',
  styleUrls: ['./data-studio.component.css']
})
export class DataStudioComponent implements OnInit, AfterViewInit{

  @ViewChild("dataStudioCard", { read: ElementRef }) dataStudioCard: ElementRef;
  @ViewChild("logo", { read: ElementRef }) logoImg: ElementRef;
  @HostListener('window:resize', ['$event'])
  public detectResize(): void {
    this.largerDimension = this.detectDataStudioLargeDimension();
  }

  constructor(private displayService: DisplayService, private tableService: TableService) {
    this.displayService.$selectedGrayOut.subscribe(greyOut=>{
      this.loading = greyOut
    });
   }
  display: string = "logo";
  viewMode: string = "navigator";
  selectedIntegration: string = null;
  largerDimension: string = null;
  title: string = null;
  tableInfo: any = null;
  public loading = false;
  //get "display" from display service. This variable drives which component is rendered in the data studio.
  //See lines 11-31 in data-studio.component.html
  //also determine which integration that a user currently has selected
  ngOnInit(): void {
    this.displayService.$dataStudioDisplay.subscribe(newDisplayValue => {
      this.display = newDisplayValue;
    });
    this.displayService.$viewMode.subscribe(newViewMode => {
      this.viewMode = newViewMode;
    });
    this.displayService.$selectedIntegration.subscribe(selectedIntegration => {
      this.selectedIntegration = selectedIntegration;
    });
    this.displayService.$dataStudioTitle.subscribe(newTitle =>{
      this.title = newTitle;
    });
    this.displayService.$selectedTableId.subscribe(tableId => {
      if(tableId != null){
        this.tableInfo = this.tableService.$tables.getValue().find(table => table.id == tableId);
      }
    });
  }

  ngAfterViewInit() {
    this.largerDimension = this.detectDataStudioLargeDimension();
  }

  // talk to display service to change the viewmode between nav and data studio (full screen)
  toggleViewMode(viewMode: string) {
    this.displayService.setViewMode(viewMode);
  }

  // change which component is displayed in the data studio
  setDisplay(display: string) {
    this.displayService.setDataStudioDisplay(display);
  }

  //Determines if the data studio's width or height is larger so that the 
  //fuze logo is rendered correctly does not stretch past the component borders.
  detectDataStudioLargeDimension(): string {
    return (this.dataStudioCard.nativeElement.offsetWidth < this.dataStudioCard.nativeElement.offsetHeight) ? "height" : "width";
  }
}
