import { FileGroupPermission } from "./file-group-permission";

export class FileGroupPermissionListing {
    public admin: boolean;
    public groups: FileGroupPermission[];

    constructor() {
        this.groups = [];
        this.admin = false;
    }
}