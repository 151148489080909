import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DisplayService } from 'src/app/services/display.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class UserCardComponent implements OnInit {

  public userImage: any = null;
  public tenantImage: any = null;
  public user: any;

  constructor(
    private tenantService: TenantService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
    public displayService: DisplayService) { 
  }
  
  // waits for user data to load, then shows that user data
  ngOnInit(): void {
    this.userService.$user.subscribe(user => {
      if (user != null) {
        this.user = user;
        this.tenantService.$tenant.subscribe(t => {
          if (t != null) {
            this.tenantImage = this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${t.logo}`);
            this.displayService.toggleSideNavGrayOut(false);
          }
        });
      }
    });
  }
}
