import { Component, Input, AfterViewInit } from '@angular/core';
import Tabulator from 'tabulator-tables';
import { RunDataContent } from '../../models/run-data-content';
import { DataLakeService } from '../../services/data-lake.service';
import { DisplayService } from 'src/app/services/display.service';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';
@Component({
  selector: 'app-tabulator-table',
  templateUrl: './tabulator-table.component.html',
  styleUrls: ['./tabulator-table.component.css']
})

//This class is specifically for Data Validation tables. These tables are read-only.

export class TabulatorTableComponent implements AfterViewInit {

  @Input() tableName: string;
  @Input() processDate: string;
  @Input() integrationName: string;

  tab = document.createElement("div");
  tabColumns: any[] = [];
  tabRows: any[] = [];
  public drawn: boolean = false;
  table: Tabulator;
  public strings: {} = {};
  public tabulatorStrings: {} = {};
  public tabulatorLangObject: any;

  constructor(private dataLakeService: DataLakeService, private displayService: DisplayService, private stringLocalizerService: StringLocalizerService) {
    this.stringLocalizerService.strings.subscribe(s => {
      if(s){
        this.strings = this.stringLocalizerService.getSection("dataValidation");
        this.tabulatorStrings = this.stringLocalizerService.getSection("tabulator");
        this.tabulatorLangObject = this.stringLocalizerService.getTabulatorLanguageObject();
      }});
  }

  // load tables, then draw tables
  ngAfterViewInit() {
    this.displayService.$selectedGrayOut.next(true);
    if (!this.drawn) {
      this.dataLakeService.getRunDataContent(this.integrationName, this.processDate, `${this.tableName}`).then(dataContent => {
        this.tabColumns = this.buildHeaders(dataContent);
        this.tabRows = this.buildRows(dataContent);
        this.redraw();
        this.drawn = true;
      });
    }
  }

  //sets the background color on cells with a validationType of "calculation"
  calculationFormatter = function (cell, formatterParams) {
    var value = cell.getValue();
    cell.getElement().style.backgroundColor = "#fdd0ff";
    return value;
  }
  lilacFormatter = function (cell, formatterParams) {
    var value = cell.getValue();
    cell.getElement().style.backgroundColor = "#d966ff";
    return value;
  }
  tealFormatter = function (cell, formatterParams) {
    var value = cell.getValue();
    cell.getElement().style.backgroundColor = "#16beac";
    return value;
  }
  redFormatter = function (cell, formatterParams) {
    var value = cell.getValue();
    cell.getElement().style.backgroundColor = " #ff8080";
    return value;
  }
  greenFormatter = function (cell, formatterParams) {
    var value = cell.getValue();
    cell.getElement().style.backgroundColor = "#80ffaa";
    return value;
  }
  blueFormatter = function (cell, formatterParams) {
    var value = cell.getValue();
    cell.getElement().style.backgroundColor = "#4d94ff";
    return value;
  }

  //creates table headers using the rundata.schema object
  buildHeaders(runData: RunDataContent): any[] {
    var headers: any[] = [];
    runData.schema.fields.forEach(f => {
      var c: any = {};
      c.title = f.name;
      c.field = f.name;
      c.sorter= 'alphanum',
      c.sorterParams={   
        alignEmptyValues:"bottom",
    }
      
      c.headerFilter = "input"
      switch (f.validationType) {
        case "calculation": {
          c.formatter = this.calculationFormatter
          break;
        }
        case "lilac": {
          c.formatter = this.lilacFormatter
          break;
        }
        case "teal": {
          c.formatter = this.tealFormatter
          break;
        }
        case "red": {
          c.formatter = this.redFormatter
          break;
        }
        case "green": {
          c.formatter = this.greenFormatter
          break;
        }
        case "blue": {
          c.formatter = this.blueFormatter
          break;
        }
        case "raw": {
        }
        case "table": {

        }

        default: {
          break;
        }
      }
      if (f.tip != null && f.tip.length > 0) {
        c.tooltip = f.tip;
        c.headerTooltip = f.tip;
      }
      headers = headers.concat(c);
    });
    return headers;
  }

  // extracts rundata.rows as rows for the table
  buildRows(runData: RunDataContent): any[] {
    return runData.rows;
  }


  // draws table and enforces certain style options
  private drawTable(): void {
    this.table = new Tabulator(this.tab, {
      layout: "fitDataStretch",
      movableColumns: true,
      height: "100%",
      pagination: "local",
      paginationSize: 25,
      paginationSizeSelector: [25, 50, 100],
      selectable: true,
      selectableRangeMode: "click",
      data: this.tabRows,
      columns: this.tabColumns,
      locale: this.stringLocalizerService.currentCulture,
      langs: this.tabulatorLangObject
    });
    document.getElementById(`${this.tableName}`).appendChild(this.tab);
    this.displayService.$selectedGrayOut.next(false);
  }


  redraw() {
    this.drawTable();
  }

  //downloads a table as CSV to the browser
  downloadCsv(){
    this.table.download("csv", `${this.tableName.replace('.json','')}.csv`);
  }

  //downloads a table as json to the browser
  downloadJson(){
    this.table.download("json", `${this.tableName.replace('.json','')}.json`);
  }
}
