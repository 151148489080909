import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '../app/components/page-not-found/page-not-found.component';
import { DashboardComponent } from '../app/components/dashboard/dashboard.component';

// Since most navigation is handled within the data studio, only a few routing endpoints are needed. 
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'signout-redirect',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  DashboardComponent,
  PageNotFoundComponent
];