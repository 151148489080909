import { Component, OnInit,  ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { DataLakeRunDataDateListing } from 'src/app/models/data-lake/run-data/data-lake-run-data-date-listing';
import { DataLakeService } from 'src/app/services/data-lake.service';
import { DisplayService } from 'src/app/services/display.service';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';

@Component({
  selector: 'app-run-data',
  templateUrl: './run-data.component.html',
  styleUrls: ['./run-data.component.css']
})

export class RunDataComponent implements OnInit { 
  //View Childs
  @ViewChild('drawer', {static: false}) public drawer?: MatDrawer
  
  // Flags
  public tablesLoading = false;
  public hasData: boolean = false;
  public noDataValidation: boolean = false;
  public isDrawerOpen: boolean = false;

  // State Data
  dataServiceDates: DataLakeRunDataDateListing[] = null
  tableNames: string[] = null;
  selectedDataValidationSolution: string;
  selectedDataValidationDate: string;
  selectedDataValidationName: string;
  strings: {} = {};

  // Subscriptions
  private solutionNameSubscription: Subscription = null;
  private tableNameSubscription: Subscription = null;

  constructor(
    private dataLakeService: DataLakeService,
    private displayService: DisplayService,
    private stringLocalizerService: StringLocalizerService
    ) {
      this.stringLocalizerService.strings.subscribe(s => {
        if(s){
          this.strings = this.stringLocalizerService.getSection("dataValidation");
        }
      });
    }

   ngOnInit() {
    this.solutionNameSubscription = this.dataLakeService.$dataLakeRunData.subscribe(runData => {
      if(runData != null){
        this.selectedDataValidationSolution = this.displayService.$selectedDataValidationSolution.value;
        var selectedRunData = runData.solutionListings.find(s => s.name == this.selectedDataValidationSolution);
        if(selectedRunData != null){
          this.dataServiceDates = selectedRunData.dateListings;
        }
      }
      else{
        this.dataServiceDates = [];
      }
    });

    // When selected Integration.Date.Table changes
    this.tableNameSubscription = this.displayService.$selectedDataValidationName.subscribe(selectedDataValidationName => {
      if(!this.isNullOrEmpty(selectedDataValidationName)) {
        this.selectedDataValidationName = selectedDataValidationName;
      }
    });
    this.displayService.$dataValidationDrawerOpen.subscribe(x => {
      this.openDrawer(x);
    })
  }

  isNullOrEmpty(value: string){
    return (value == null || value == "")
  }

  updateTableDataFlags(){
    if (this.tableNames.length > 0) {
      this.hasData = true;
      this.tablesLoading= false;
      this.noDataValidation = false;
    }
    else {
      this.tablesLoading= false;
      this.noDataValidation = true;
    }
  }

  // get the names of all data validation files within a given date folder.
  async GetTables(date: string): Promise<string[]> {
    var tables: string[] = []; 
    this.tablesLoading = true
    this.hasData =false;
    await this.dataLakeService.loadDataLakeRunData(this.selectedDataValidationSolution, date); 
    var solutionListings = this.dataLakeService.$dataLakeRunData.value.solutionListings;
    var solutionObject = solutionListings.find(s => s.name == this.selectedDataValidationSolution);
    if(solutionObject != null){
      var dateObject = solutionObject.dateListings.find(d => d.name == this.selectedDataValidationDate)
      if(dateObject != null){
        if(dateObject.runDataListings != null && dateObject.runDataListings.length > 0){
          tables = tables.concat(dateObject.runDataListings.map(table => table.name.replace('.json', '')));
        }
      }
    }
    return tables;
  }

  setDataStudioTitle(title:string){
    this.displayService.setDataStudioTitle(title);
  }

  // track when the mat-drawer is open or closed
  onOpenedChange(event) {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  public async selectDataValidationDate(date:string){

    if(this.selectedDataValidationDate != date){
      this.displayService.$selectedGrayOut.next(true);
      this.selectedDataValidationDate = date;
      this.displayService.selectDataValidationDate(date);
      this.tableNames = await this.GetTables(this.selectedDataValidationDate);
      this.updateTableDataFlags();
      this.displayService.$selectedGrayOut.next(false);
    }
  }

  public selectDataValidationName($event){
    let name = this.tableNames[$event.index]+'.json'
    this.selectedDataValidationName = name;
    this.displayService.selectDataValidationName(name);
  }
  openDrawer(x){
    if(x == true){
      this.drawer?.open();
    }
  }
}
