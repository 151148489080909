<mat-card #dataStudioCard class="card">
    <div class="background-logo"*ngIf="this.display == 'logo'"
     [ngClass]="{
        'logobox-wide': largerDimension == 'width',
        'logobox-tall': largerDimension == 'height' 
        }"
        >
   <img class="logo" src=" ../../assets/images/dayforce-blue.svg">
    </div>
    <div *ngIf="this.display == 'tables'" class="tables data-studio-child">
        <app-tabulator-user-table [tableInfo]="tableInfo"></app-tabulator-user-table>
    </div>
    <div *ngIf="this.display == 'runlog'" class="runlog data-studio-child">
        <app-run-log></app-run-log>
    </div>
    <div *ngIf="this.display == 'rundata'" class="data-validation data-studio-child">
        <app-run-data></app-run-data>
    </div>
    <div *ngIf="this.display == 'action'" class="action data-studio-child">
        <app-action></app-action>
    </div>
    <div *ngIf="this.display == 'errors'" class="errors data-studio-child">
        <app-errors></app-errors>
    </div>
    <div *ngIf="this.display == 'debug'" class="errors data-studio-child">
        <app-debug-info></app-debug-info>
    </div>
    <div *ngIf="this.display == 'admin'" class="errors data-studio-child">
        <app-admin></app-admin>
    </div>
    <div *ngIf="this.display == 'dataLake'" class="data-lake data-studio-child">
        <app-data-lake></app-data-lake>
    </div>
</mat-card>
