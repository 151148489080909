import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';

export const httpLoaderFactory = (httpClient: HttpClient) => {
  const config$ = httpClient.get<any>(`assets/config.json`).pipe(
    map((customConfig: any) => {
      return {
        authority: customConfig.dfid.authority,
        redirectUrl: customConfig.dfid.redirectUrl,
        postLogoutRedirectUri: customConfig.dfid.postLogoutRedirectUri,
        clientId: customConfig.dfid.clientId,
        scope: customConfig.dfid.scope,
        responseType: customConfig.dfid.responseType,
        silentRenew: customConfig.dfid.silentRenew,
        useRefreshToken: customConfig.dfid.useRefreshToken,
        renewTimeBeforeTokenExpiresInSeconds: customConfig.dfid.renewTimeBeforeTokenExpiresInSeconds,
      };
    })
  );

  return new StsConfigHttpLoader(config$);
};

@NgModule(
  {
    imports: [
      AuthModule.forRoot({
          loader: {
          provide: StsConfigLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        }
      })],
    exports: [AuthModule],
})
export class AuthConfigModule {}
