import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private user: any;
  public $user: BehaviorSubject<any>;

  constructor(private authService: AuthService) {
    this.user = {};
    this.$user = new BehaviorSubject<any>(this.user);

    this.authService.$userClaims.subscribe(claims => {
      if (claims != null && claims.length > 0) {
        this.user["claims"] = claims;
        this.user["clientNameSpace"] = claims.find(claim => claim.type == "df.ns").value;
        this.user["id"] = claims.find(claim => claim.type == "df.userid").value;
        this.user["email"] = this.user["claims"] != null ? this.user["claims"].find(claim => claim.type == "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier").value : "UNKNOWN_NOTFOUND";
        this.user["name"] = claims.find(claim => claim.type == "preferred_username").value;
        this.user["culture"] = claims.find(claim => claim.type == "df.culture").value;
        this.$user.next(this.user);
      }
    });
  }
}