import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SignalRConnection } from '../models/signal-rconnection';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { DurableFunctionService } from './durable-function.service';
import { DataLakeService } from './data-lake.service';
import { TenantService } from './tenant.service';
import { BYPASS_LOG } from './http-oidc-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private signalRCloseRequested: boolean = false;
  private hubConnection: HubConnection;

  private negotiateUrl: string;
  private message$: Subject<string>;
  public messageObservable$: Observable<string>;
  public connected: boolean = false;

  constructor(
    private http: HttpClient,
    private durableFunctionService: DurableFunctionService,
    private dataLakeService: DataLakeService,
    private tenantService: TenantService) {

    tenantService.$tenant.subscribe(t => {
      if(t != null){
        this.negotiateUrl = `${t.solutionUrl}/api/negotiate`;
      }  
    })
    this.message$ = new Subject<string>();
    this.messageObservable$ = this.message$.asObservable();
  }


  // Estables a websockets connection with the durable function. 
  // used to transmit real-time communications between the durable function app, and the web client.
  public async startSignalR(environment: string): Promise<void> {
    var signalRConnectionInfo: SignalRConnection;
    var options: signalR.IHttpConnectionOptions;

    switch(environment) {
      case "PROD":
          this.negotiateUrl = `${this.tenantService.tenant.solutionUrl}/api/negotiate`;
        break;
      case "TEST":
        this.negotiateUrl = `${this.tenantService.tenant.solutionTestUrl}/api/negotiate`;
        break;
      case "DEV":
        this.negotiateUrl = `${this.tenantService.tenant.solutionDevUrl}/api/negotiate`;
        break;
    }
    await this.stopSignalR();
    console.log("starting signalR");

    this.tenantService.$tenant.subscribe(t => {
      if (t != null) {
        this.http.get<SignalRConnection>(this.negotiateUrl, { context: new HttpContext().set(BYPASS_LOG, true) }).subscribe(async info => {
          signalRConnectionInfo = info;
          options = {
            accessTokenFactory: () => signalRConnectionInfo.accessToken
          };
          this.announceMessage('User received SignalR JWT.');

          this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(signalRConnectionInfo.url, options)
            .configureLogging(signalR.LogLevel.Information)
            .build();

          await this.hubConnection.start().
            then(() => {
              this.announceMessage('Hub Connection Started.');
              this.connected = true;
            }).catch(
              err => {
                this.announceErrorMessage(err);
                this.connected = false;
              }
            );

          // Updates in exection state are handled here. Used to track when a solution has started, and when the solution has finished running.
          this.hubConnection.on('notify', async (data: any) => {
            var dat = JSON.parse(data);
            console.log(`solution: ${dat["solutionName"]} -- message: ${dat["message"]}`);
            if (dat["message"] == "Process Started") {
              console.log(dat);
              this.durableFunctionService.loadIntegrationHistory(dat["solutionName"]);
            }
            else if (dat["message"] == "Process Completed" || dat["message"] == "Process Failed") {
              await this.delay(5000);
              this.durableFunctionService.loadIntegrationHistory(dat["solutionName"]);
              this.dataLakeService.loadDataLakeRunDataSolutionNames();
              this.dataLakeService.loadOutputSolutionNames();
            }
          });

          this.hubConnection.onclose((error) => {
            if (this.signalRCloseRequested) {
              this.signalRCloseRequested = false;
              this.connected = false;
              return;
            }
            if (this.hubConnection) {
              this.hubConnection.start().
                then(() => {
                  this.announceMessage('Hub Connection Restarted.');
                  this.connected = true;
                }).catch(
                  err => {
                    this.announceErrorMessage(err);
                    this.connected = false;
                  }
                );
            }
            if (error) {
              this.announceErrorMessage(error);
              this.connected = true;
            }
          });
        });
      }
    });
  }

  // Close websockets connection
  public async stopSignalR(): Promise<void> {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.signalRCloseRequested = true;
      this.hubConnection.off('notify');
      this.connected = false;
      return await this.hubConnection.stop();
    }
    return;
  }

  private announceMessage(message: string): void {
    this.message$.next(message);
  }

  private announceErrorMessage(content: any): void {
    if (content instanceof HttpErrorResponse || content instanceof Error) {
      this.announceMessage(`Error: ${content.message}`);
    } else {
      this.announceMessage(content);
    }
  }

  delay(delay: number) {
    return new Promise(r => {
      setTimeout(r, delay);
    });
  }
}
