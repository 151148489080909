import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { CosmosTable } from '../models/cosmos/cosmos-table';
import { CosmosTableRow } from '../models/cosmos/cosmos-table-row';
import { CosmosTableSchema } from '../models/cosmos/cosmos-table-schema';
import { TableData } from '../models/table-data';
import { TenantService } from './tenant.service';
import { AuthService } from './auth-service.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public $tables: BehaviorSubject<CosmosTable[]> = new BehaviorSubject<CosmosTable[]>(null);
  private tables: CosmosTable[] = [];
  private tableDataSets: TableData[] = [];
  public selectedTable;
  private clientNameSpace;
  private baseUrl: string;

  constructor(public tenantService: TenantService, private http: HttpClient, private authService: AuthService, private configService: ConfigService) {
    this.configService.$configuration.subscribe(config => {
      if (config != null) {
        this.baseUrl = config.servicesUrl;
      }
    });
    this.authService.$userClaims.subscribe(claims => {
      if (claims != null && claims.length > 0) {
        var clientNameSpace = claims.find(claim => claim.type == "df.ns").value;
        this.clientNameSpace = clientNameSpace;
      }
    });
  }

  public async loadTables() {
    var schemaIds = [];
    var schemaIdCheck = {};

    if (this.tableDataSets.length == 0) {
      await this.loadTableDataSets();
    }
    this.tables = this.tableDataSets.map(t => new CosmosTable(t));
    this.tables.forEach(t => {
      if (!schemaIdCheck[t.schemaId]) {
        schemaIds.push(t.schemaId);
        schemaIdCheck[t.schemaId] = true;
      }
    });
    //could filter in if table is read or write
    this.tables = this.tables.sort(this.tableSortFunction);
    this.$tables.next(this.tables);
  }

  public selectTable(tableId: string) {
    if (this.tables != null) {
      this.selectedTable = this.tables.find(t => t.id == tableId);
    }
  }

  private async loadTableDataSets(nameSpaceOverride: string = null) {
    var tableIdsUrl = `${this.baseUrl}/api/${nameSpaceOverride != null ? nameSpaceOverride : this.clientNameSpace}/TableData`;
    await this.http.get<TableData[]>(tableIdsUrl).toPromise().then(async tables => {
      this.tableDataSets = tables
    })
      .catch(e => {
        console.error(e);
      });
  }

  public async getTableDataSets(nameSpaceOverride: string = null): Promise<TableData[]> {
    if (this.tableDataSets.length == 0) {
      await this.loadTableDataSets(nameSpaceOverride);
    }
    return this.tableDataSets;
  }

  tableSortFunction = (a, b) => {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (!a.data.order && !b.data.order) {
      // Both orders values are null - sort by alpha
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    else if ((a.data.order != null && !b.data.order) || (!a.data.order && b.data.order != null)) {
      // One order value is null, assign priority to non-null value
      if (!a.data.order) {
        return 1;
      } else {
        return -1;
      }
    }
    else if (a.data.order == b.data.order) {
      // Both order values are equal, sort by alpha
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    else {
      if (a.data.order < b.data.order) {
        return -1;
      }
      if (a.data.order > b.data.order) {
        return 1;
      }
    }
    return 0;
  };

  async loadSelectedTable() {
    if (this.selectedTable != null) {
      // ---LOAD SCHEMA---
      //Build URL to load schema
      var schemasUrl = `${this.baseUrl}/api/${this.clientNameSpace}/TableSchema/${this.selectedTable.schemaId}`;
      //HTTP GET schema
      await this.http.get<CosmosTableSchema>(schemasUrl).toPromise().then(schema => {
        this.selectedTable.schema = schema
      }).catch(e => {
        console.error(e)
      });

      // ---LOAD ROWS---
      //Build URL to load rows
      var rowsUrl = `${this.baseUrl}/api/${this.clientNameSpace}/TableData/${this.selectedTable.id}?runInParallel=true`;
      //HTTP GET rows
      await this.http.get<TableData>(rowsUrl).toPromise().then(tableData => {
        this.selectedTable.data.id = tableData.id;
        this.selectedTable.data.itemType = tableData.id;
        this.selectedTable.data.name = tableData.name;
        this.selectedTable.data.schemaId = tableData.schemaId;
        this.selectedTable.data.partitionKey = tableData.partitionKey;
        this.selectedTable.data.rows = [];
        tableData.rows.forEach(row => {
          var rowData = new CosmosTableRow();
          rowData.currentValue = row;
          rowData.initialValue = row;
          this.selectedTable.data.rows.push(rowData);
        });
      }).catch(e => {
        console.error(e)
      });
    }

  }

  public addTableRow(rowData: any, tableId: string) {
    var table = this.tables.find(t => t.id == tableId);
    var newRow = new CosmosTableRow();
    newRow.currentValue = rowData;
    newRow.initialValue = rowData;
    table.data.rows.push(newRow);
  }


  public editRow(rowData: any, tableId: string) {
    var table = this.tables.find(t => t.id == tableId);
    var row = table.data.rows.find(r => r.currentValue.id == rowData.data["id"]);
    row.currentValue = rowData.data;
  }

  public getInitialValues(rowId: string, tableId: string) {
    var table = this.tables.find(t => t.id == tableId);
    var row = table.data.rows.find(r => r.initialValue.id == rowId);
    return row.initialValue;
  }

  public async importTableRowsFromFile(rows: {}[], tableId: string): Promise<boolean> {
    var ret = false;
    var table = this.tables.find(t => t.id == tableId);
    var body = {};
    var schema: CosmosTableSchema = table.schema
    body["id"] = table.data.id;
    body["schemaId"] = table.data.schemaId;
    body["name"] = table.data.name;
    body["rows"] = rows;
    var json = JSON.stringify(body);
    var upsertUrl = `${this.baseUrl}/api/${this.tenantService.$tenant.value.id}/${tableId}`;
    await this.http.put<TableData>(upsertUrl, body).toPromise().then(tableData => {
      // update $tables
      rows.forEach(updatedRow => {
      });
      ret = true;
    }).catch(e => {
      return false;
    });
    return ret;
  }

  public async updateTableRows(rows: any[], tableDataId: string): Promise<boolean> {
    var ret = false;
    var table = this.tables.find(t => t.id == tableDataId);
    var body = {};
    var schema: CosmosTableSchema = table.schema
    body["id"] = table.data.id;
    body["partitionKey"] = table.data.partitionKey;
    body["schemaId"] = table.data.schemaId;
    body["name"] = table.data.name;
    body["rows"] = [];
    rows.map(row => {
      var updatedRow = {};
      schema.columns.forEach(column => {
        updatedRow[column.columnName] = row._row.data[column.columnName];
        updatedRow["rowState"] = row._row.data["rowState"];
        updatedRow["id"] = row._row.data["id"];
        updatedRow["partitionKey"] = `${body["partitionKey"]}|${body["id"]}`;
      });
      body["rows"].push(updatedRow);
    });
    var json = JSON.stringify(body);
    var upsertUrl = `${this.baseUrl}/api/${this.clientNameSpace}/TableData/${tableDataId}`;
    await this.http.put<TableData>(upsertUrl, body).toPromise().then(tableData => {
      // update $tables
      rows.forEach(updatedRow => {
        if (updatedRow.rowState == 2) {
          var newRow = new CosmosTableRow();
          schema.columns.forEach(column => {
            newRow.currentValue[column.columnName] = updatedRow._row.data[column.columnName];
            newRow.initialValue[column.columnName] = updatedRow._row.data[column.columnName];
            table.data.rows.push(newRow);
          });
        } else {
          var originalRow = table.data.rows.find(o => o.initialValue.id == updatedRow._row.data.id);
          for (const [key, value] of Object.entries(originalRow)) {
            originalRow.currentValue[key] = updatedRow[key];
            originalRow.initialValue[key] = updatedRow[key];
          }
        }
      });
      ret = true;
    }).catch(e => {
      return false;
    });
    return ret;
  }
}
