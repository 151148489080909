import { Component, OnInit } from '@angular/core';
import {  OidcSecurityService } from 'angular-auth-oidc-client';
import { EnvironmentService } from 'src/app/services/environment.service';
import { TenantService } from 'src/app/services/tenant.service';
import { DisplayService } from 'src/app/services/display.service';

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrls: ['./debug-info.component.css']
})
export class DebugInfoComponent implements OnInit {

  public accessToken: string;
  public idToken: string;
  public refreshToken: string;
  public tenantId: string;
  public schemas: any[];
  public durableFunctionEnvironment: string = "PROD";
  public datalakeEnvironment: string = "PROD";

  constructor(private tenantService: TenantService,
    private oidcSecurityService: OidcSecurityService,
    private displayService: DisplayService,
    private environmentService: EnvironmentService) {
      this.getDebugInfo();
      this.environmentService.$durableFunctionEnvironment.subscribe(e => {
        this.durableFunctionEnvironment = e;
      });
      this.environmentService.$datalakeEnvironment.subscribe(e => {
        this.datalakeEnvironment = e;
      });
    }

  ngOnInit(): void {
     this.displayService.setSelectedSpanText("");
  }

  private getDebugInfo(){
    this.tenantService.$tenant.subscribe(t => {
      if(t != null){
        this.tenantId = t.id;
      }
    });
    this.oidcSecurityService.getIdToken().subscribe((token) => this.idToken = token)
    this.oidcSecurityService.getAccessToken().subscribe((token) => this.accessToken = token)
    this.oidcSecurityService.getRefreshToken().subscribe((token) => this.refreshToken = token)
  }

  public async changeDurableFunctionEnvironment(environment: string){
    if(environment != this.durableFunctionEnvironment){
      this.environmentService.setDurableFunctionEnvironment(environment);
      this.durableFunctionEnvironment = environment;
    }
  }

  public async changeDatalakeEnvironment(environment: string){
    if(environment != this.datalakeEnvironment){
      this.environmentService.setDatalakeEnvironment(environment);
      this.datalakeEnvironment = environment;
    }
  }
}