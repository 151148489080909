<div [ngClass]="{'status-container': true, 'status-container-static': !isDeleting, 'status-container-deleting':isDeleting}">
    <div class="icon-container">
        <mat-icon matTooltip="This instance completed successfully" matTooltipPosition="left" *ngIf="isCompleted" class="done-icon status-icon">check_circle</mat-icon>
        <mat-icon matTooltip="This instance completed successfully" matTooltipPosition="left" *ngIf="isContinuedAsNew" class="done-icon status-icon">check_circle</mat-icon>
        <img *ngIf="isFailed" matTooltip="This instance failed" matTooltipPosition="left" class="failed-icon status-icon"  src="../../assets/images/icons/FilllargeX.svg">
        <img *ngIf="isCanceled" matTooltip="This instance was cancelled" matTooltipPosition="left" class="failed-icon status-icon" src="../../../assets/images/delete-icon.svg">
        <img  src="../../assets/images/icons/FillnotActive.svg" *ngIf="isTerminated" matTooltip="This instance was manually terminated" matTooltipPosition="left" class="terminated-icon status-icon">
    </div>
    <div class="instance-controls">
        <div class="text-container">
            <b class="date-text">{{date}}</b>
        </div>
        <div class="week-container">
            <div class="weekday-container">
                <b [ngClass]="{'weekday-icon': dayOfWeek != 1, 'weekday-icon-highlighted': dayOfWeek == 1}" class="sunday-icon">Su</b>
            </div>
            <div class="weekday-container">
                <b [ngClass]="{'weekday-icon': dayOfWeek != 2, 'weekday-icon-highlighted': dayOfWeek == 2}" class="monday-icon">M</b>
            </div>
            <div class="weekday-container">
                <b [ngClass]="{'weekday-icon': dayOfWeek != 3, 'weekday-icon-highlighted': dayOfWeek == 3}" class="tuesday-icon">T</b>
            </div>
            <div class="weekday-container">
                <b [ngClass]="{'weekday-icon': dayOfWeek != 4, 'weekday-icon-highlighted': dayOfWeek == 4}" class="wednesday-icon">W</b>
            </div>
            <div class="weekday-container">
                <b [ngClass]="{'weekday-icon': dayOfWeek != 5, 'weekday-icon-highlighted': dayOfWeek == 5}" class="thursday-icon">Th</b>
            </div>
            <div class="weekday-container">
                <b [ngClass]="{'weekday-icon': dayOfWeek != 6, 'weekday-icon-highlighted': dayOfWeek == 6}" class="friday-icon">F</b>
            </div>
            <div class="weekday-container">
                <b [ngClass]="{'weekday-icon': dayOfWeek != 7, 'weekday-icon-highlighted': dayOfWeek == 7}" class="saturday-icon">Sa</b>
            </div>
        </div>
        <mat-icon *ngIf="(isUnknown || isRunning) && !isDeleting" (click)="terminateInstance()" class="terminate-icon" matTooltip="Cancel Instance" matTooltipPosition="left">
            stop
        </mat-icon>
        <img src="../../assets/images/icons/Fillcancel.svg" *ngIf="(isCompleted || isContinuedAsNew || isFailed || isCanceled || isTerminated) && !isDeleting" (click)="deleteInstance()" class="delete-icon" matTooltip="Delete Instance" matTooltipPosition="left">
    </div>
</div>