import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TenantConfiguration } from '../models/tenant-configuration';
import { MyAccess } from '../models/my-access';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth-service.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private baseUrl: string;
  public $tenant = new BehaviorSubject<TenantConfiguration>(null);
  public tenant: TenantConfiguration;
  public myAccess: MyAccess;

  //Ensure that the user data has loaded. Then use the tenantId within the
  // user data to retrieve tenant data from the configuration service API
  constructor(private configService: ConfigService,  private http: HttpClient, private authService: AuthService) {
    this.configService.$configuration.subscribe(config => {
      if(config != null){
        this.baseUrl = config.servicesUrl;
      }
    });
    this.authService.$userClaims.subscribe(claims => {
      if(claims != null && claims.length > 0){
        var clientNameSpace = claims.find(claim => claim.type == "df.ns").value;
        this.loadTenant(clientNameSpace);
        this.loadAccess(clientNameSpace);
      }
    });
  }

  public async loadTenant(clientNameSpace: string) {
    var url = `${this.baseUrl}/api/${clientNameSpace}/Tenant`
    await this.http.get<TenantConfiguration>(url).subscribe(t => {
      this.tenant = t;
      this.$tenant.next(this.tenant);
    });
  }

  public async loadAccess(clientNameSpace: string) {
    var url = `${this.baseUrl}/api/${clientNameSpace}/MyAccess`;
    await this.http.get<MyAccess>(url).subscribe(access => {
      this.myAccess = access;
    });
  }
}


