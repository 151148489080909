import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public $durableFunctionEnvironment: BehaviorSubject<string>;
  public $datalakeEnvironment: BehaviorSubject<string>;

  constructor(private configService: ConfigService){
    var env = "DEV";
    this.$durableFunctionEnvironment = new BehaviorSubject<string>(env);
    this.$datalakeEnvironment = new BehaviorSubject(env);

    this.configService.$configuration.subscribe(config => {
      if(config != null){
        env = config.environment != null ? config.environment : "DEV";
        this.setDurableFunctionEnvironment(env);
        this.setDatalakeEnvironment("PROD");
      }
    });
  }

  public setDatalakeEnvironment(environment: string) {
    switch(environment) {
      case "PROD":
        this.$datalakeEnvironment.next(environment);
        break;
      case "TEST":
        this.$datalakeEnvironment.next(environment);
        break;
      case "DEV":
        this.$datalakeEnvironment.next(environment);
        break;
    }
  }

  public setDurableFunctionEnvironment(environment: string) {
    switch(environment) {
      case "PROD":
        this.$durableFunctionEnvironment.next(environment);
        break;
      case "TEST":
        this.$durableFunctionEnvironment.next(environment);
        break;
      case "DEV":
        this.$durableFunctionEnvironment.next(environment);
        break;
    }
  }
}
