import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CosmosTable } from 'src/app/models/cosmos/cosmos-table';
import { TableService } from 'src/app/services/table.service';
import Tabulator from 'tabulator-tables';
import { TableImportServiceService } from 'src/app/services/table-import-service.service';
import * as moment from 'moment';
import { bearerTokenAuthenticationPolicy } from '@azure/core-http';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-drag-n-drop',
  templateUrl: './drag-n-drop.html',
  styleUrls: ['./drag-n-drop.css']
})
export class DragNDrop {
  @Input() tableId: string = "";
  uploadedFile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  errorsList: string[] = [];
  files: any[] = [];
  finalJson: any[] = [];
  table: any;
  csvData: string;
  lines: string[] = [];
  headers: string[] = [];
  headerMenu = [];
  tabulatorTable: Tabulator = null;
  public deletionRow: number;
  public tableLoaded: boolean = false;

  constructor(
    private tableService: TableService,
    private tableImportServiceService: TableImportServiceService,
    private tenantService: TenantService
  ) {
    this.tableImportServiceService.$tableLoaded.subscribe(t => {
      if(t != null){
        this.tableLoaded = t;
      }
    });
  }

  ngOnInit(): void {
    this.uploadedFile.subscribe(async file => {
      if (file != null) {
        await this.constructTableData(file);
        this.renderTable();
      }
    });
    this.table = this.tableService.$tables.value.find(t => t.id == this.tableId);
    this.table.schema.columns.forEach(col => {
      this.headerMenu.push(
        {
          // label is schema headers, alias
          label: col.columnName,

          action: function (e, column) {
            //click function that makes it the header of the column
            var name = column._column.definition.field;
            var newTitle = col.columnName;
            column._column.table.updateColumnDefinition(name, { title: newTitle });
            
            //Determine expected datatype of column designation
            var schema = column._column.table.customSchemaObject.schema;
            var type = schema.columns.find(c => c.columnName == newTitle).columnDataType;
            column.customDataType = type;
            
            var columnGroup = column._column.table.columnManager.columns;
            var headerSelectedColumns = columnGroup.find(x => x.definition.title == col.columnName).cells.forEach(cell => {
              cell["columnDataType"] = col.columnDataType;
            });
            if (newTitle != "") {
              column._column.table.columnManager.columns.forEach(c => {

                if (c.definition.title == newTitle && c.definition.field != name) {
                  column._column.table.updateColumnDefinition(c.definition.field, { title: "N/A" });
                }
              });
            }
          }
        });
    });
    this.headerMenu.push({
      label: "(clear)",
      action: function (e, column) {
        var name = column._column.definition.field;
        column._column.table.updateColumnDefinition(name, { title: "N/A" });
      }
    });

  }
  async constructTableData(file: any): Promise<void> {
    var stream = file.stream();
    var reader = stream.getReader();
    await reader.read()
      .then(data => {
        //Convert CSV file into string
        var string = new TextDecoder().decode(data.value);
        this.csvData = string;

        //Break the CSV string into line-terminated records
        this.lines = string.split("\n");

        //Measure how many headers should be rendered
        var maxLength = 0;
        this.lines.forEach(l => {
          var recordLength = l.split(",").length;
          if (recordLength >= maxLength) {
            maxLength = recordLength;
          }
        });

        //Create empty header array
        for (var i = 0; i < maxLength; i++) {
          this.headers.push(`${i}`);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }
  renderTable(): void {
    this.tabulatorTable = new Tabulator("#example-table", {
      height: 500,
      data: this.buildRows(),
      columns: this.buildColumns(),
      layout: "fitColumns",
      placeholder: "Awaiting Data, Please Load File",
    });
    this.tabulatorTable["customSchemaObject"] = this.table; 
    this.tableImportServiceService.$tableLoaded.next(true);

    this.tableImportServiceService.$headerDeletionNumber.subscribe(_ => {
      this.updateFilters(this.tabulatorTable);
    });
    this.tableImportServiceService.$footerDeletionNumber.subscribe(_ => {
      this.updateFilters(this.tabulatorTable);
    });
  }

  // typeFormatter = function (cell, formatterParams){
  //   var value = cell.getValue();
  //   var column = cell._cell.column;
  //   if(column.customDataType != null){
  //     switch(column.customDataType){
  //       case 1:
  //         break;
  //       case 2: // Integer
  //         if (Number.isInteger(Number(value)) == false) {
  //           cell.getElement().style.color = "#ffffff";              //CHANGE CELL WHITE FONT
  //           cell.getElement().style.backgroundColor = "#e68a00";   //CHANGE CELL GOLD COLOR
  //         }
  //         break;
  //       case 3: // Decimal
  //         if (isNaN(cell.value)) {
  //           cell.getElement().style.color = "#ffffff";              //CHANGE CELL WHITE FONT
  //           cell.getElement().style.backgroundColor = "#e68a00";   //CHANGE CELL GOLD COLOR
  //         }
  //         if (cell.value == Math.floor(cell.value)) {
  //           cell.getElement().style.color = "#ffffff";              //CHANGE CELL WHITE FONT
  //           cell.getElement().style.backgroundColor = "#e68a00";   //CHANGE CELL GOLD COLOR
  //         }
  //         break;
  //       case 4: // Boolean
  //         if (cell.value !== "FALSE" && cell.value !== "TRUE") {
  //           cell.getElement().style.color = "#ffffff";              //CHANGE CELL WHITE FONT
  //           cell.getElement().style.backgroundColor = "#e68a00";   //CHANGE CELL GOLD COLOR
  //         }
  //         break;
  //       case 5: // Date
  //         if (moment(cell.value, "MM/DD/YYYY").isValid() == false) {
  //           cell.getElement().style.color = "#ffffff";              //CHANGE CELL WHITE FONT
  //           cell.getElement().style.backgroundColor = "#e68a00";   //CHANGE CELL GOLD COLOR
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }

  private updateFilters(table: Tabulator) {
    var firstRow = this.tableImportServiceService.$headerDeletionNumber.value;
    var lastRow = this.tableImportServiceService.rowCount - this.tableImportServiceService.$footerDeletionNumber.value - 1;
    
    function customFilter(data, filterParams) {
      var firstRow: number = filterParams["firstRow"];
      var lastRow: number = filterParams["lastRow"];
      return data.rowNumber >= firstRow && data.rowNumber <= lastRow; //must return a boolean, true if it passes the filter.
    }

    table.setFilter(customFilter, { firstRow: firstRow, lastRow: lastRow })
  }

  buildRows(): {}[] {
    var rows: {}[] = [];
    var rowNumber = 0;
    this.lines.forEach(line => {
      var row = {};
      var splitLine = line.split(",");
      for (var i = 0; i < splitLine.length; i++) {
        row[this.headers[i]] = splitLine[i];
      }
      row["rowNumber"] = rowNumber;
      rowNumber++;
      rows.push(row);
    });
    this.tableImportServiceService.rowCount = rowNumber;

    return rows;
  }

  buildColumns(): {}[] {
    var columns: {}[] = [];
    var n = 0;
    this.headers.forEach(h => {
      columns.push({ 
        title: "N/A", 
        field: `${n++}`, 
        headerMenu: this.headerMenu
      });
    });

    return columns;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);

  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {

        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === undefined) { console.log("files undefined") }
          if (this.files[index].progress === 100) {
            this.upload();
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);

  }

  upload() {
    var tab = this.tabulatorTable;
    var schema = this.table.schema;
    var rowData: {}[] = [];
    var schemaData: {}[] = [];

    //determine which column indexes are useful
    tab.columnManager.columns.forEach(tabulatorColumn => {
      var schemaColumn = schema.columns.find(c => c.columnName == (tabulatorColumn.definition.title))
      if(schemaColumn != undefined){
        schemaData.push({index: Number(tabulatorColumn.definition.field), column: schemaColumn});
      }
    });

    //create row data
    tab.rowManager.activeRows.forEach(tabulatorRow => {
      try{
        var row: any = {};
        schemaData.forEach(s => {
          var rawValue = tabulatorRow.cells[s["index"]].value;
          if(rawValue != null){
            var value = rawValue.replaceAll("\"","").trim();
          }
          switch(s["column"].columnDataType){
            case 1:
              if(value != null){
                row[s["column"].columnName] = value;
              }
              break;
            case 2:
              if(value != null && value != ""){
                row[s["column"].columnName] = new Number(value).valueOf();
              }
              break;
            case 3:
              if(value != null && value != ""){
                row[s["column"].columnName] = new Number(value).valueOf();
              }
              break;
            case 4:
              if(value != null && value != ""){
                row[s["column"].columnName] = new Boolean(value).valueOf();
              }
              break;
            case 5:
              if(value != null && value != ""){
                row[s["column"].columnName] = new Date(value);
              };
              break;
            default:
              break;
          }
          row[s["column"].columnName] 
        });
        row["rowState"] = 2;
        rowData.push(row);
      }
      catch(e){
        var errorMsg = "";
        schemaData.forEach(function(val, key, schemaData){
          var rawValue = tabulatorRow.cells[val["index"]].value;
          errorMsg = `${errorMsg}${rawValue}`;
          if(!Object.is(schemaData.length - 1, key)){
            errorMsg = `${errorMsg}, `;
          }   
        });
        this.errorsList.push(errorMsg);
      }
    });
    console.log(this.errorsList);
    this.tableImportServiceService.importTableData(rowData, this.tableId);

  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      this.uploadedFile.next(item);
    }
    // this.uploadFilesSimulator(0);

  }

  // tableData =
  //   [{ "EmployeeXrefCode": "40056", "BalanceXrefCode": "SICK_HOURS", "AsOfDate": "2022-03-06T00:00:00", "Amount": 3.498, "IsTransaction": true, "TreatAmountAsTransaction": true, "Key": "40056|SICK_HOURS|YYYY0306" }, { "EmployeeXrefCode": "100620", "BalanceXrefCode": "SICK_HOURS", "AsOfDate": "2022-03-06T00:00:00", "Amount": 2.640, "IsTransaction": true, "TreatAmountAsTransaction": true, "Key": "100620|SICK_HOURS|YYYY0306" }, { "EmployeeXrefCode": "215065", "BalanceXrefCode": "SICK_HOURS", "AsOfDate": "2022-03-06T00:00:00", "Amount": 1.320, "IsTransaction": true, "TreatAmountAsTransaction": true, "Key": "215065|SICK_HOURS|YYYY0306" }, { "EmployeeXrefCode": "00039", "BalanceXrefCode": "SICK_HOURS", "AsOfDate": "2022-03-06T00:00:00", "Amount": 2.640, "IsTransaction": true, "TreatAmountAsTransaction": true, "Key": "00039|SICK_HOURS|YYYY0306" }, { "EmployeeXrefCode": "104099", "BalanceXrefCode": "SICK_HOURS", "AsOfDate": "2022-03-06T00:00:00", "Amount": 2.640, "IsTransaction": true, "TreatAmountAsTransaction": true, "Key": "104099|SICK_HOURS|YYYY0306" }, ]


  selectedHeaderDeletionNumber(value){
    this.tableImportServiceService.$headerDeletionNumber.next(value)
  }
  selectedFooterDeletionNumber(value){
      this.tableImportServiceService.$footerDeletionNumber.next(value)
  }
}
