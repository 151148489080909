import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TableService } from './table.service';

@Injectable({
  providedIn: 'root'
})
export class TableImportServiceService {
  public $headerDeletionNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  public rowCount: number;
  public $footerDeletionNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  public tableData: any;
  public $tableLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private tableService: TableService
  ) { }

  public selectHeaderDeletionNumber(deletionNumber:number) {
    this.$headerDeletionNumber.next(deletionNumber);
  }

  public selectFooterDeletionNumber(deletionNumber:number) {
    this.$footerDeletionNumber.next(deletionNumber);
  }

  public importTableData(rows: {}[], tableId: string){
    this.tableService.importTableRowsFromFile(rows, tableId);
  }
}