import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor, HttpContextToken} from '@angular/common/http';
import { Injectable,} from '@angular/core';
import { Observable } from 'rxjs';

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable({
    providedIn: 'root'
})
export class HttpOIDCInterceptorService implements HttpInterceptor {
    constructor() {}

    // This method catches outgoing HTTP requests, and adds an authorization header, with the users access token.
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (httpRequest.context.get(BYPASS_LOG) === true){
            return next.handle(httpRequest);
        } else {
            return next.handle(httpRequest.clone({ withCredentials:true}));
        }
        
    }
}
