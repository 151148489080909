<div>
    <label>Access Token</label>
    <p>{{accessToken}}</p>
    <label>ID Token</label>
    <p>{{idToken}}</p>
    <label>Refresh Token</label>
    <p>{{refreshToken}}</p>
    <label>Tenant ID</label>
    <p>{{tenantId}}</p>
    <p>Durable Function Environent</p>
    <button (click)="changeDurableFunctionEnvironment('PROD')" [ngClass]="{'active-button': durableFunctionEnvironment === 'PROD'}">PROD</button>
    <button (click)="changeDurableFunctionEnvironment('TEST')" [ngClass]="{'active-button': durableFunctionEnvironment === 'TEST'}">TEST</button>
    <button (click)="changeDurableFunctionEnvironment('DEV')" [ngClass]="{'active-button': durableFunctionEnvironment === 'DEV'}">DEV</button>
    <p></p>
    <p></p>
    <p>Datalake Environment</p>
    <button (click)="changeDatalakeEnvironment('PROD')" [ngClass]="{'active-button': datalakeEnvironment === 'PROD'}">PROD</button>
    <button (click)="changeDatalakeEnvironment('TEST')" [ngClass]="{'active-button': datalakeEnvironment === 'TEST'}">TEST</button>
    <button (click)="changeDatalakeEnvironment('DEV')" [ngClass]="{'active-button': datalakeEnvironment === 'DEV'}">DEV</button>
</div>