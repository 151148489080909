<mat-card class="integrations-card">
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabClick($event)" [selectedIndex]="selectedIndex" #tabs>
        <mat-tab *ngFor="let connection of connections | async">
            <ng-template matTabLabel>
                <span>{{connection.name}}</span>
            </ng-template>
            <div class="integration-buttons">
                <button mat-raised-button [ngClass]="{
                    'integration-button-nav': navMode,
                    'integration-button-ds': !navMode
                    }" (click)="setDisplay('rundata')" color="accent">Data Validation</button>
                <button mat-raised-button [ngClass]="{
                    'integration-button-nav': navMode,
                    'integration-button-ds': !navMode
                    }" (click)="setDisplay('dataLake')" color="accent">Data Lake</button>
                <button mat-raised-button [ngClass]="{
                    'integration-button-nav': navMode,
                    'integration-button-ds': !navMode
                    }" (click)="setDisplay('errors')" color="accent">Errors</button>
                <button mat-raised-button [ngClass]="{
                    'integration-button-nav': navMode,
                    'integration-button-ds': !navMode
                    }" (click)="setDisplay('runlog')" color="accent">Process Log</button>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>