export class DataLakeFileListing {
    public name: string;
    public extension: string;

    constructor(fileName: string) {
        var elements = fileName.split(".");
        var nameStart = "";
        var extension: string = null;
        for (let i = 0; i < elements.length - 1; i++) {
            if (i == 0) {
                nameStart = elements[i];
            } else {
                nameStart = `${nameStart}.${elements[i]}`;
            }
        }
        if (elements.length >= 2) {
            extension = elements[elements.length - 1];
        }
        this.name = nameStart;
        this.extension = extension;
    }
}
