<div class="user-card">
    <div #splashContainer *ngIf="user" class="user-card-top">
        <img *ngIf="tenantImage" class="splash" [src]="tenantImage">
    </div>
    <div class="user-card-bottom">
        <div class="h-spacer"></div>
        <div class="user-card-bottom" *ngIf="user">
            <img mat-card-avatar *ngIf="userImage" [src]="userImage" class="user-image">
            <mat-card-title class="font-name" *ngIf="user">{{user.preferred_username}}</mat-card-title>
        </div>
    </div>
</div>