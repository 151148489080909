import { RunDataTableSchema } from './run-data-table-schema';

export class RunDataContent {
    schema: RunDataTableSchema;
    rows: any[];
    order: number;

    public constructor(){
        this.schema = null;
        this.rows = [];
    }
}
