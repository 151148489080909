<div *ngIf="loaderService.isLoading | async" class="loader">
    <mat-spinner style="top: 50%; left: 50%;"></mat-spinner>
</div>
<app-toolbar class="toolbar"></app-toolbar>
<mat-sidenav-container class="layout-grid" [ngClass]="{'overlay-sidenav-container':  loaderService.isLoading |async}">

    <mat-sidenav #sidenav mode="side" class="side-draw" opened [ngClass]="{'overlayLoading':  loaderService.isLoading |async}">
        <app-user-card #userCard class="user-card" [style.display]="navMode ? '' : 'none'"></app-user-card>
        <app-side-nav class="primary-buttons" [style.display]="navMode ? '' : 'none'"></app-side-nav>
    </mat-sidenav>

    <mat-sidenav-content #dataStudio class="data-studio" [ngClass]="{'overlay-data-studio': loaderService.isLoading |async}">
        <div class="title">
            <button class="side-chevron" mat-button (click)="onOpenedChange($event)" (click)="sidenav.toggle()">
                <img src="../../assets/images/icons/FillchevronRight.svg"
                    [ngClass]="{ 'chevron-left': isDrawerOpen==false, 'chevron-right': isDrawerOpen==true }">
            </button>
            <div class="title-div">
                {{buttonTitle}}
                <div class="arrows" *ngIf="spanTitle">/</div>
                {{spanTitle}}
                <div class="arrows" *ngIf="title">/</div>
                {{title}}
            </div>
            <div class="toast-container">
                <app-toast class="toast"></app-toast>
            </div>
        </div>
        <app-data-studio class="data-studio-content"></app-data-studio>
    </mat-sidenav-content>
</mat-sidenav-container>