<mat-toolbar  class="toolbar">
    <div mat-icon-button class="fuze-icon">
        <img class="logo" src=" ../../assets/images/dayforce-blue.svg">
    </div>

    <div *ngIf="durableFunctionEnvironment == 'DEV'">
        <img class="logo" src=" ../../../assets/images/Function-Apps.svg">
        <img class="logo" src=" ../../../assets/images/dev.svg">
    </div>
    <div *ngIf="durableFunctionEnvironment == 'TEST'">
        <img class="logo" src=" ../../../assets/images/Function-Apps.svg">
        <img class="logo" src=" ../../../assets/images/test.svg">
    </div>

    <div *ngIf="datalakeEnvironment == 'DEV'">
        <img class="logo" src=" ../../../assets/images/Storage-Accounts.svg">
        <img class="logo" src=" ../../../assets/images/dev.svg">
    </div>
    <div *ngIf="datalakeEnvironment == 'TEST'">
        <img class="logo" src=" ../../../assets/images/Storage-Accounts.svg">
        <img class="logo" src=" ../../../assets/images/test.svg">
    </div>

    <span class="toolbar-spacer-middle" *ngIf="!showPrimaryButtons"></span>
    <div class="buttons">
        <button *ngIf="strings" matTooltip="{{strings['logOff']}}" matTooltipPosition="below" mat-icon-button class="nav-icon" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
        </button>
    </div>
</mat-toolbar>