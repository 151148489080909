import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IntegrationDetails } from '../models/integration-details';
import { IntegrationStatus } from '../models/integration-status';
import { SolutionHistory } from '../models/solution-history';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';
import { EnvironmentService } from './environment.service';
import * as moment from 'moment';
import { BYPASS_LOG } from './http-oidc-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class DurableFunctionService {

  public $integrations = new BehaviorSubject<IntegrationDetails[]>(null);
  private integrationHistories: SolutionHistory[] = [];
  private environment: string = "DEV";
  private user: any;

  // Wait for tenant service to finish loading,
  // Tenant data will contain durable function URL
  // Load instance history from durable function using urls
  // Also load user data and pass user info in requests to durable function for tracking/security
  constructor(
    private httpClient: HttpClient,
    private tenantService: TenantService,
    private userService: UserService,
    private environmentService: EnvironmentService) {

    this.tenantService.$tenant.subscribe(tenant => {
      if (tenant != null) {
        this.$integrations.next(tenant.integrations);
        tenant.integrations.forEach(i => {
          this.integrationHistories = this.integrationHistories.concat(new SolutionHistory(i.name));
        });
      }
    });
    this.userService.$user.subscribe(user => {
      if (user != null) {
        this.user = user;
      }
    });
    this.environmentService.$durableFunctionEnvironment.subscribe(e => {
      this.environment = e;
    });
  }

  public listIntegrations(): IntegrationDetails[] {

   let a = this.$integrations.getValue();
   return a;
  }

  // Build POST request to trigger integration durable function. 
  // Include action parameters from tenant.integration[x].actionp[x]
  // Also include user and tenant info.
  public runIntegration(integrationName: string, actionName: string, params: any[]) {
    var solutionUrl = this.solutionUrl();
    var integration = this.$integrations.value.find(i => i.name == integrationName);
    var action = integration.actions.find(a => a.name == actionName);
    var url = `${solutionUrl}/${integration.solutionUrlExtension}`;
    if (action != null) {
      var postBody = {};
      params.forEach(p => {
        if (p.value != null) {
          if (p.dataType != 5) {
            postBody[`${p.name}`] = `${p.value}`;
          } else {
            let date = p.value.toISOString();
            postBody[`${p.name}`] = `${date}`;
          }
        } else {
          if (p.defaultValue) {
            postBody[`${p.name}`] = `${p.defaultValue}`;
          } else {
            if (p.dataType != 4) {
              postBody[`${p.name}`] = `${null}`;
            } else {
              postBody[`${p.name}`] = `${false}`;
            }
          }
        }
      });
      if (this.tenantService.$tenant.value != null) {
        var tenant = this.tenantService.$tenant.value
        if (this.environmentService.$datalakeEnvironment.value == "PROD") {
          postBody["dataLakeAccountName"] = tenant.dataLakeAccountName;
          postBody["dataLakeAccessKey"] = tenant.dataLakeAccessKey;
        }
        else if (this.environmentService.$datalakeEnvironment.value == "TEST") {
          postBody["dataLakeAccountName"] = tenant.dataLakeTestAccountName;
          postBody["dataLakeAccessKey"] = tenant.dataLakeTestAccessKey;
        }
        else if (this.environmentService.$datalakeEnvironment.value == "DEV") {
          postBody["dataLakeAccountName"] = tenant.dataLakeDevAccountName;
          postBody["dataLakeAccessKey"] = tenant.dataLakeDevAccessKey;
        }
        postBody["clientDataServiceUrl"] = tenant.tenantDataServiceUrl;
        postBody["solutionName"] = integrationName;
        postBody["userName"] = this.user.name;
        postBody["userEmail"] = this.user.email;
        postBody["tenantId"] = this.tenantService.$tenant.value.id;
        postBody["actionName"] = actionName;
        postBody["environment"] = this.environment;
        postBody["culture"] = this.user.culture;
      }
      this.httpClient.post(url, postBody, { context: new HttpContext().set(BYPASS_LOG, true) }).toPromise<any>().then(async _ => {
        this.loadIntegrationHistory(integrationName);
      });
    }
  }

  //the initial load of history will be handed by this.loadIntegrationHistory(). This method will return the history for a given integration within the history.
  public getHistory(integrationName: string): BehaviorSubject<IntegrationStatus[]> {
    this.integrationHistories = this.integrationHistories.concat(new SolutionHistory(integrationName));
    return this.integrationHistories.find(iH => iH.name == integrationName).statusListing;
  }

  // Loads the durable function execution instance history using the durable functions API
  public async loadIntegrationHistory(integrationName: string) {
    var solutionUrl = this.solutionUrl();
    var integrationDetails = this.$integrations.value.find(i => i.name == integrationName)
    if (integrationDetails != null) {
      if (solutionUrl != null && integrationDetails.solutionUrlExtension != null) {
        var url = `${solutionUrl}/api/DurableInstances/${integrationName}`;
        this.httpClient.get<any[]>(url, { context: new HttpContext().set(BYPASS_LOG, true) }).toPromise().then(s => {
          var status: IntegrationStatus[] = [];
          s.forEach(stat => {
            var newStatus = JSON.parse(stat);
            status = status.concat(newStatus);
          });
          status = status.sort((a, b) => {
            return Date.parse(b.createdTime) - Date.parse(a.createdTime);
          });
          status.forEach(s => {
            s.alias = integrationName;
          });
          this.integrationHistories.find(iH => iH.name == integrationName).statusListing.next(status);
        });
      }
    }
  }

  // Deletes a specific execution instance from the history of a durable function from the server.
  public async deleteInstance(instanceId: string, alias: string, name: string) {
    var solutionUrl = this.solutionUrl();
    var url = `${solutionUrl}/api/DeleteInstance/${alias}/${instanceId}`;
    await this.httpClient.get(url, { context: new HttpContext().set(BYPASS_LOG, true) }).toPromise<any>().then(async _ => {
      await this.delay(2000);
      await this.loadIntegrationHistory(alias);
    });
  }

  // Terminates the execution of a running instance a durable function.
  public async terminateInstance(instanceId: string, alias: string, name: string) {
    var solutionUrl = this.solutionUrl();
    var url = `${solutionUrl}/api/TerminateInstance/${instanceId}`;
    await this.httpClient.get(url, { context: new HttpContext().set(BYPASS_LOG, true) }).toPromise<any>().then(async _ => {
      await this.delay(2000);
      await this.loadIntegrationHistory(alias);
    });
  }

  // Arbitrary delay timer
  delay(delay: number) {
    return new Promise(r => {
      setTimeout(r, delay);
    });
  }

  private solutionUrl() {
    if (this.tenantService.tenant != null) {
      if (this.environment == "TEST") {
        return this.tenantService.tenant.solutionTestUrl;
      }
      else if (this.environment == "DEV") {
        return this.tenantService.tenant.solutionDevUrl;
      }
      else {
        return this.tenantService.tenant.solutionUrl;
      }
    }
  }
}
