import { Component, Input, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { DateListing } from 'src/app/models/date-listing';
import { DataLakeService } from 'src/app/services/data-lake.service';
import { DisplayService } from 'src/app/services/display.service';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';


@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})
export class ErrorsComponent implements OnInit {
  integrationName: string;
  public tablesLoading = false;
  dates: DateListing[];
  errors: string[];
  date: string;
  hasData: boolean = false;
  showNoLogs: boolean = false;
  saveIconShow: boolean = true;
  public isDrawerOpen: boolean = false;
  public strings: {} = {};
  @ViewChild('drawer', {static: false}) public drawer?: MatDrawer;

  constructor(
    private dataLakeService: DataLakeService,
    private displayService: DisplayService,
    private stringLocalizerService: StringLocalizerService) { 
      this.stringLocalizerService.strings.subscribe(s => {
        if (s) {
          this.strings = this.stringLocalizerService.getSection("errorLogs");
        }
      })
  }

  // determine which integration is currently selected by the user
  // get a list of dated error logs for the currently selected integration
 ngOnInit() {
    this.displayService.$selectedErrorLogDrawer.subscribe(open => {
    this.dataLakeService.$dataLakeRunData.subscribe(runData => {
      if(runData != null){
        var selectedSolutionName = this.displayService.$selectedErrorLogSolution.value;
        this.integrationName = selectedSolutionName; 
        var selectedRunData = runData.solutionListings.find(s => s.name == selectedSolutionName);
        if(selectedRunData != null){
          this.dates = selectedRunData.dateListings;
          this.drawer?.open();
        }
      }
      else{
        this.dates = [];
        this.drawer?.open();
      }
    });
    });
  }

  // load & render a selected integration's error log
  async setData(date: string) {
    this.displayService.$selectedGrayOut.next(true);
    this.date = date;
    this.tablesLoading = true
    this.hasData =false;
    this.errors = await (await this.dataLakeService.getLog(this.integrationName, date, "ErrorLog")).contents;
    if(this.errors.length == 0){
      this.showNoLogs = true;
      this.saveIconShow = false;
      this.displayService.$selectedGrayOut.next(false);
    }else{
      this.showNoLogs = false;
      this.saveIconShow = true;
      this.displayService.$selectedGrayOut.next(false);
    }
    this.hasData = true;
    this.displayService.$selectedGrayOut.next(false);
  }
  //Set and tracks title in bottom left of screen
  setDataStudioTitle(title:string){
    this.displayService.setDataStudioTitle(title);
  }
 
  //Track when the mat-drawer is opened or closed
  onOpenedChange(event){
    this.isDrawerOpen = !this.isDrawerOpen;
  }
  
  downloadJson(){
     this.dataLakeService
    .downloadLog(this.integrationName,this.date, "ErrorLog").then(blob => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = `ErrorLog.json`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  openDrawer(x){
    if(x == true){
    this.drawer?.open();
  }
}
}
