import { TableData } from '../table-data';
import { CosmosTableData } from './cosmos-table-data';
import { CosmosTableSchema } from './cosmos-table-schema';

export class CosmosTable {
    public name: string;
    public alias: string;
    public id: string;
    public schemaId;
    public schema: CosmosTableSchema;
    public data: CosmosTableData;
    public order: number;
    public editPermission?: boolean = false;

    constructor(tableData: TableData) {
        this.name = tableData.name;
        this.alias = tableData.name;
        this.id = tableData.id;
        this.schemaId = tableData.schemaId;
        if (tableData.order) {
            this.order = tableData.order;
        }
        this.data = new CosmosTableData();
    }
}
