import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TenantService } from 'src/app/services/tenant.service';
import { TableService } from 'src/app/services/table.service';
import { UserService } from 'src/app/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { SignalRService } from 'src/app/services/signalr.service';
import { IntegrationDetails } from 'src/app/models/integration-details';
import { DisplayService } from 'src/app/services/display.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  //Components
  @ViewChild("layoutGrid", { read: ElementRef }) layoutGrid: ElementRef;
  @ViewChild("dataStudio", { read: ElementRef }) dataStudio: ElementRef;
  @ViewChild("iDash", { read: ElementRef }) iDash: ElementRef;
  @ViewChild("primaryButtons", { read: ElementRef }) primaryButtons: ElementRef;
  @ViewChild("userCard", { read: ElementRef }) userCard: ElementRef;
  public events: string[] = [];
  public isDrawerOpen: boolean = true;
  ev: string[] = [];
  opened: boolean;
  grayOutBreadcrumbs: boolean = false;

  //Size Detection
  @HostListener('window:resize', ['$event'])
  public detectResize(event): void {
    if (this.tryUpdateWidthMode()) {
      if (this.displayService.$viewMode.value == "data studio") {
      }
    }
  }

  private $connections = new BehaviorSubject<IntegrationDetails[]>([]);
  public connections = this.$connections.asObservable();

  public display: string = 'logo';
  public hasTables: boolean = false;
  public tablesLoading: boolean = true;
  public user: any;
  private width: number;
  navMode: boolean = true;
  widthMode: string = null;
  public showUserCard: boolean = true;
  public integrationButtonClass = "integration-button-nav-600";
  setToasterDisplay: boolean = false;
  sideNavGrayOut: boolean = false;

  constructor(
    private tenantService: TenantService,
    private signalRService: SignalRService,
    private tableService: TableService,
    private userService: UserService,
    private displayService: DisplayService,
    private environmentService: EnvironmentService,
    public loaderService: LoaderService,
    private authService: AuthService) 
    { 
      this.displayService.$selectedGrayOut.subscribe(greyOut=>{
        this.grayOutBreadcrumbs = greyOut
      });
      this.displayService.$sideNavGrayOut.subscribe(grayOut =>{
        this.sideNavGrayOut = grayOut
      });
    }

  runDataBadge: boolean = false;
  public title: string = null;
  public buttonTitle: string = null;
  public spanTitle: string = null;


  // Wait for user to load, get user.tenantId to load tenant data from Cosmos
  // Wait for tenant to load
  // Wait for connections to load, and select first integration in collection
  // Wait for tables to load, then enable tables button
  // Connect to Durable Function SignalR
  ngOnInit() {
    // await this.startOktaService();
    this.tenantService.$tenant.subscribe(t => {
      if (t != null) {
        this.environmentService.$durableFunctionEnvironment.subscribe(e => {
          if (this.signalRService.connected == true) {
            this.signalRService.stopSignalR();
          }
          this.signalRService.startSignalR(e);
        });
        this.$connections.next(t.integrations.map(i => i));
        this.tableService.$tables.subscribe(tables => {
          if (tables != null) {
            if (tables.length > 0) {
              this.hasTables = true;
            }
            this.tablesLoading = false;
          }
        });
      }
    });
    this.displayService.$dataStudioTitle.subscribe(newTitle => {
      this.title = newTitle;
    })
    this.displayService.$selectedSpanText.subscribe(newTitle => {
      this.spanTitle = newTitle;
    })
    this.displayService.$primaryButtonText.subscribe(newTitle => {
      this.buttonTitle = newTitle;
    });
  }

  // Ensure that the data studio is sized/formatted correctly
  private tryUpdateWidthMode(): boolean {
    this.width = window.innerWidth;
    if (!this.widthMode) {
      this.widthMode = this.calculateWidthMode();
      return true;
    }
    else {
      var newWidthMode = this.calculateWidthMode();
      if (this.widthMode != newWidthMode) {
        this.widthMode = newWidthMode;
        return true;
      }
    }
    return false;
  }

  // Determine page layout, based on the width of the user's browser.
  private calculateWidthMode(): string {
    if (this.width <= 599) {
      return "599";
    }
    else if (this.width >= 600 && this.width <= 899) {
      return "600";
    }
    else if (this.width >= 900 && this.width <= 1199) {
      return "900";
    }
    else {
      return "1200";
    }
  }

  //Track which integration that a user has selected. Used for choosing which components to render
  selectIntegration(integrationName: string) {
    this.displayService.$selectedIntegration.next(integrationName);
  }

  //Track if mat-drawer is open or close.
  onOpenedChange(event) {
    this.isDrawerOpen = !this.isDrawerOpen;
  }
}

