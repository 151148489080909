import * as moment from 'moment';
import { DataLakeRunDataListing } from './data-lake-run-data-listing';

export class DataLakeRunDataDateListing {
    public name: string;
    public runDataListings: DataLakeRunDataListing[];
    public runLog: string[];
    public errorLog: string[];
    public utcDate: string;
    public longDate: string;

    public constructor(isoDate: string){
        this.name = isoDate;
        this.runDataListings = [];
        this.utcDate = isoDate;
        var lastChar = (this.utcDate.substring(this.utcDate.length-1,this.utcDate.length))
        if(lastChar == "Z") {
            this.longDate = moment(new Date(this.utcDate)).format("MMM DD YYYY hh:mm A")
        } else {
            this.longDate = this.utcDate;
        }
    }
}
