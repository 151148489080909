import { FileGroup } from "../file-group";

export class FileGroupPermission{
    public name: string;
    public permission: string;

    constructor(fileGroup: FileGroup) {
        this.name = fileGroup.id;
        this.permission = "Read"
    }
}