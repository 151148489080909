import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataLakeFileDateListing } from '../models/data-lake/files/data-lake-file-date-listing';
import { DataLakeFileDirectory } from '../models/data-lake/files/data-lake-file-directory';
import { DataLakeFileListing } from '../models/data-lake/files/data-lake-file-listing';
import { DataLakeFileSolutionListing } from '../models/data-lake/files/data-lake-file-solution-listing';
import { DataLakeRunDataDateListing } from '../models/data-lake/run-data/data-lake-run-data-date-listing';
import { DataLakeRunDataDirectory } from '../models/data-lake/run-data/data-lake-run-data-directory';
import { DataLakeRunDataListing } from '../models/data-lake/run-data/data-lake-run-data-listing';
import { DataLakeRunDataSolutionListing } from '../models/data-lake/run-data/data-lake-run-data-solution-listing';
import { DirectoryListing } from '../models/directory-listing';
import { RunDataContent } from '../models/run-data-content';
import { RunLog } from '../models/run-log';
import { EnvironmentService } from './environment.service';
import { TenantService } from './tenant.service';
import { DisplayService } from 'src/app/services/display.service';
import { AuthService } from './auth-service.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DataLakeService {
  public $dataLakeRunData: BehaviorSubject<DataLakeRunDataDirectory>
  public $dataLakeFiles: BehaviorSubject<DataLakeFileDirectory>;
  private dataLakeRunData: DataLakeRunDataDirectory;
  private dataLakeFiles: DataLakeFileDirectory;
  private dataLakeKey: string;
  private dataLakeAccount: string;
  private dataLakeEnvironment: string = null;
  private clientNameSpace;
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private environmentService: EnvironmentService,
    private displayService: DisplayService,
    private authService: AuthService,
    private configService: ConfigService) {

    this.configService.$configuration.subscribe(config => {
      if(config != null){
        this.baseUrl = config.servicesUrl;
      }
    });
    this.authService.$userClaims.subscribe(claims => {
      if (claims != null && claims.length > 0) {
        var clientNameSpace = claims.find(claim => claim.type == "df.ns").value;
        this.clientNameSpace = clientNameSpace ? clientNameSpace : "UNKNOWN_NOTFOUND";
      }
    });
    this.$dataLakeRunData = new BehaviorSubject<DataLakeRunDataDirectory>(null);
    this.$dataLakeFiles = new BehaviorSubject<DataLakeFileDirectory>(null);
    this.LoadTenantData();
  }

  private async LoadTenantData() {
    await this.tenantService.$tenant.subscribe(async t => {
      if (t != null) {
        await this.environmentService.$datalakeEnvironment.subscribe(async environment => {
          if (environment == "DEV") {
            this.dataLakeKey = t.dataLakeDevAccessKey;
            this.dataLakeAccount = t.dataLakeDevAccountName;
          }
          else if (environment == "TEST") {
            this.dataLakeKey = t.dataLakeTestAccessKey;
            this.dataLakeAccount = t.dataLakeTestAccountName;
          }
          else if (environment == "PROD") {
            this.dataLakeKey = t.dataLakeAccessKey;
            this.dataLakeAccount = t.dataLakeAccountName;
          }
          if (this.dataLakeEnvironment == null || this.dataLakeEnvironment != environment) {
            this.resetDataLakeBehaviorSubjects();
          }
        });
      }
    });
  }

  private resetDataLakeBehaviorSubjects() {
    this.$dataLakeRunData.next(null);
    this.$dataLakeFiles.next(null);
  }

  //Loads all solution names in the data validation container, within the data lake
  public async loadDataLakeRunDataSolutionNames() {
    var updatedSolutionNames = await this.getRunDataSolutions();
    var existingSolutionNames = [];
    if (this.dataLakeRunData != null) {
      this.dataLakeRunData.solutionListings.forEach(s => existingSolutionNames.push(s.name));
    }
    if (!(updatedSolutionNames.contents.every(item => existingSolutionNames.includes(item))
      && existingSolutionNames.every(item => updatedSolutionNames.contents.includes(item)))) {
      this.dataLakeRunData = new DataLakeRunDataDirectory();
      this.dataLakeRunData.solutionListings = updatedSolutionNames.contents.map(s => new DataLakeRunDataSolutionListing(s));
      this.$dataLakeRunData.next(this.dataLakeRunData);
    }
  }

  public async loadDataLakeRunDataDates(integrationName) {
    var integration = this.dataLakeRunData.solutionListings.find(s => s.name == integrationName);
    if (integration != null) {
      await this.getRunDataDates(integration.name).then(async dates => {
        var newDates = [];
        dates.contents.map(dateName => newDates = newDates.concat(new DataLakeRunDataDateListing(dateName)));
        integration.dateListings = newDates.reverse();
        this.$dataLakeRunData.next(this.dataLakeRunData);
      });
    }
  }


  public async loadDataLakeRunData(solutionName: string, date: string) {
    var runDataInstance = this.dataLakeRunData.solutionListings.find(s => s.name == solutionName).dateListings.find(d => d.name == date);
    if (runDataInstance.runDataListings.length == 0) {
      await this.getRunDataFiles(solutionName, date).then(dataListing => {
        dataListing.contents.map(fileName => runDataInstance.runDataListings = runDataInstance.runDataListings.concat(new DataLakeRunDataListing(fileName)));
      });
      if (runDataInstance.runDataListings.length != 0) {
        this.displayService.selectDataValidationName(runDataInstance.runDataListings[0].name);
        await this.getRunDataContent(solutionName, date, runDataInstance.runDataListings[0].name).then(runDataContent => {
          runDataInstance.runDataListings[0].runDataContent = runDataContent;
          if (runDataContent.order == undefined) {
            runDataContent.order = 65535
          }
        });
      }
      this.$dataLakeRunData.next(this.dataLakeRunData);
    }
  }

  // Create an index of all output instance dates, for a given solution, from the output folder on the data lake
  public async loadOutputSolutionNames() {
    this.dataLakeFiles = new DataLakeFileDirectory();
    var solutions = await this.GetIOSolutions();
    this.dataLakeFiles.solutionListings = solutions.contents.map(s => new DataLakeFileSolutionListing(s))
    this.$dataLakeFiles.next(this.dataLakeFiles);
  }

  public async loadOutputDates(integrationName) {
    var integration = this.dataLakeFiles.solutionListings.find(s => s.name == integrationName);
    if (integration != null) {
      await this.getIODates(integration.name).then(async dates => {
        var newDates = [];
        dates.contents.map(dateName => newDates = newDates.concat(new DataLakeFileDateListing(dateName)));
        integration.dateListings = newDates.reverse();
        this.$dataLakeFiles.next(this.dataLakeFiles);
      });
    }
  }

  // Create an index of all output file names, for a given solution, for a given instance
  public async loadDataLakeFiles(solutionName: string, date: string) {
    var filesInstance = this.dataLakeFiles.solutionListings.find(s => s.name == solutionName).dateListings.find(d => d.name == date);
    await this.getIOFiles(solutionName, date).then(fileListing => {
      filesInstance.filesListing = fileListing.contents.map(fileName => new DataLakeFileListing(fileName));
    });
    this.$dataLakeFiles.next(this.dataLakeFiles);
  }

  //RunData =================================================================================
  // Loads a list of solutions, as names, from the data validation folder on the data lake
  public async getRunDataSolutions(): Promise<DirectoryListing> {
    var url = this.createDataLakeUrl("rundata", "solutions");
    var listing = new DirectoryListing();
    var solutionsPromise = this.http.get<DirectoryListing>(url).toPromise()
      .then(dl => { listing = dl; })
      .catch(e => { console.log(`Error retreiving RunData Solutions from url ${url}. Exception:${e}`) });
    await solutionsPromise;
    return listing;
  }

  // loads a list of data validation instances, as dates, for a given solution, from the data validation folder on the data lake
  public async getRunDataDates(solution: string): Promise<DirectoryListing> {
    var url = this.createDataLakeUrl("rundata", "dates", solution);
    var listing = new DirectoryListing();
    var datesPromise = this.http.get<DirectoryListing>(url).toPromise()
      .then(l => { listing = l; })
      .catch(e => { console.log(`Error retreiving RunData Dates Listing from url ${url}. Exception:${e}`) });
    await datesPromise;
    return listing;
  }

  // Loads a list of data validation files, as names, for a given solution, for a given date, from the data validation folder on the data lake
  public async getRunDataFiles(solution: string, date: string): Promise<DirectoryListing> {
    var url = this.createDataLakeUrl("rundata", "files", solution, date);
    var listing = new DirectoryListing();
    await this.http.get<DirectoryListing>(url).toPromise()
      .then(l => { listing = l })
      .catch(e => { console.log(`Error retreiving RunData Files Listing from url ${url}. Exception:${e}`) });
    return listing;
  }

  // downloads a data validation file for a given solution, for a given instance, from the data validation folder on the data lake
  public async getRunDataFile(solution: string, date: string, file: string): Promise<Blob> {
    var url = this.createDataLakeUrl("rundata", "file", solution, date, file, "file");
    var fileBlob: Blob = null;
    await this.http.get(url, { responseType: 'blob' }).toPromise()
      .then(c => { fileBlob = c })
      .catch(e => { console.log(`Error retreiving RunData File from url ${url}. Exception:${e}`) });
    return fileBlob;
  }

  // Loads data validation for a given solution, for a given instance, from the data validation folder on the data lake
  public async getRunDataContent(solution: string, date: string, file: string): Promise<RunDataContent> {
    var url = this.createDataLakeUrl("rundata", "file", solution, date, file, "data");
    var content = new RunDataContent();
    await this.http.get<RunDataContent>(url).toPromise()
      .then(c => { content = c })
      .catch(e => { console.log(`Error retreiving RunData Content from url ${url}. Exception:${e}`) });
    return content;
  }

  //IO =================================================================================
  // Loads a list of solutions, as names, from the output folder on the Data lake
  public async GetIOSolutions(): Promise<DirectoryListing> {
    var url = this.createDataLakeUrl("io", "solutions");
    var listing = new DirectoryListing();
    await this.http.get<DirectoryListing>(url).toPromise()
      .then(l => { listing = l })
      .catch(e => { console.log(`Error retreiving IO Solutions Listing from url ${url}. Exception:${e}`) });
    return listing;
  }

  // Loads a list of instances, as dates, for a given solution from the output folder on the Data Lake
  public async getIODates(solution: string): Promise<DirectoryListing> {
    var url = this.createDataLakeUrl("io", "dates", solution);
    var listing = new DirectoryListing();
    await this.http.get<DirectoryListing>(url).toPromise()
      .then(l => { listing = l })
      .catch(e => { console.log(`Error retreiving IO Dates from url ${url}. Exception:${e}`) });
    return listing;
  }

  // Loads the list of files for a given instance of a solution from the output folder on the Data Lake
  public async getIOFiles(solution: string, date: string): Promise<DirectoryListing> {
    var url = this.createDataLakeUrl("io", "files", solution, date);
    var listing = new DirectoryListing();
    await this.http.get<DirectoryListing>(url).toPromise()
      .then(l => { listing = l })
      .catch(e => { console.log(`Error retreiving IO Files from url ${url}. Exception:${e}`) });
    return listing;
  }

  // downloads a specified file for a specific solution, for a specific instance, from the output folder on the Data Lake
  public async getIOFile(solution: string, date: string, filename: string): Promise<Blob> {
    var url = this.createDataLakeUrl("io", "file", solution, date, filename, "file");
    var fileBlob: Blob = null;
    await this.http.get(url, { responseType: 'blob' }).toPromise()
      .then(c => { fileBlob = c })
      .catch(e => { console.log(`Error retreiving IO File from url ${url}. Exception:${e}`) });
    return fileBlob;
  }

  //Logs =================================================================================
  // Load the runlog or error log for a specific instance of a specific solution. LogType == "RunLog" or "ErrorLog"
  public async getLog(solution: string, date: string, logType: string): Promise<RunLog> {
    var url = this.createDataLakeUrl("rundata", logType, solution, date)
    var log = new RunLog();
    await this.http.get<RunLog>(url).toPromise()
      .then(l => { log = l; })
      .catch(e => { console.log(`Error retreiving runlog from url ${url}. Exception:${e}`) });
    return log;
  }

  public async downloadLog(solution: string, date: string, logType: string): Promise<Blob> {
    var url = this.createDataLakeUrl("rundata", logType, solution, date, null, "file");
    var fileBlob: Blob = null;
    await this.http.get(url, { responseType: 'blob' }).toPromise()
      .then(c => { fileBlob = c })
      .catch(e => { console.log(`Error retreiving IO File from url ${url}. Exception:${e}`) });
    return fileBlob;
  }

  //URLs =================================================================================
  // This method builds API Endpoint URLS for communicating with the Data Lake
  private createDataLakeUrl(container: string, type: string, solutionName?: string, dateName?: string, fileName?: string, mode?: string): string {
    var url = `${this.baseUrl}/api`;

    if (container == "rundata") {
      url = `${url}/${this.clientNameSpace}/RunData/solutions`;
      if (type == "ErrorLog" || type == "RunLog") {
        url = `${url}/${solutionName}/dates/${dateName}/logs/${type}.json`;
      }
      else if (type == "solutions" || type == "dates" || type == "files" || type == "file") {
        if (solutionName != null) { url = `${url}/${solutionName}/dates`; }
        if (dateName != null) { url = `${url}/${dateName}/files`; }
        if (fileName != null) { url = `${url}/${fileName}`; }
      }
    }

    else if (container == "io") {
      url = `${url}/${this.clientNameSpace}/IO/solutions`;
      if (type == "solutions" || type == "dates" || type == "files" || type == "file") {
        if (solutionName != null || fileName != undefined) { url = `${url}/${solutionName}/dates`; }
        if (dateName != null || fileName != undefined) { url = `${url}/${dateName}/files`; }
        if (fileName != null || fileName != undefined) { url = `${url}/${fileName}`; }
      }
    }

    url = `${url}?account=${this.dataLakeAccount}`;
    url = `${url}&key=${this.dataLakeKey}`;
    if (mode != null) { url = `${url}&mode=${mode}` }
    return url;
  }
}